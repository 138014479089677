<div class="text-center">
  <h2 class="headline-x-small mb-6" [innerText]="'LogIn.PleaseCheckYourEmailInbox' | translate"></h2>

  <div class="well mb-6">
    <p class="body-medium mb-2" [innerHtml]="'LogIn.WeHaveSentAnEmail' | translate: { email: email }"></p>
    <p class="body-medium" [innerText]="'LogIn.FollowTheLinkToLogIn' | translate"></p>
  </div>

  <p class="body-medium">
    <span [innerText]="'LogIn.HaveNotReceivedAnEmail' | translate"></span>
    <span>&nbsp;</span>
    <a class="cursor-pointer" [innerText]="'LogIn.SendAgain' | translate" (click)="sendAgainEvent.emit()">Send Again</a>
  </p>
</div>
