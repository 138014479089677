import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

@Directive({
  selector: '[formGroup]',
})
export class FormGroupDirective {
  @Input('formGroup') formGroup?: any; // we have a hard time using FormGroup here due to weird typing issues

  private _updateValueAndValidity(group: FormGroup | FormArray | AbstractControl) {
    group.markAsTouched();
    group.updateValueAndValidity();

    if (group instanceof FormGroup) {
      for (let control of Object.values(group.controls)) {
        this._updateValueAndValidity(control);
      }
    } else if (group instanceof FormArray) {
      for (let control of group.controls) {
        this._updateValueAndValidity(control);
      }
    }
  }

  @HostListener('submit', ['$event'])
  public updateFormValidity(event: Event) {
    if (this.formGroup) {
      this._updateValueAndValidity(this.formGroup);
    }
  }
}
