import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoginService } from '../login.service';
import { ToastService } from '../../../shared/services/toast.service';
import { showSpinner } from '../../../shared/utils/common.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login-email',
  templateUrl: './login-email.component.html',
  styleUrl: './login-email.component.scss',
})
export class LoginEmailComponent {
  constructor(
    private toastService: ToastService,
    private loginService: LoginService,
    private translateService: TranslateService,
  ) {}

  @Input() email?: string;
  @Output() cancelEvent: EventEmitter<void> = new EventEmitter<void>();

  sendLogInEmail() {
    if (this.email) {
      this.loginService
        .sendLoginEmail(this.email)
        .pipe(showSpinner())
        .subscribe(() => {
          this.toastService.add({
            message: this.translateService.instant('LogIn.WeHaveSentAnEmailToast', { email: this.email }),
            type: 'success',
          });
        });
    }
  }

  cancel(): void {
    this.cancelEvent.emit();
  }
}
