<div class="flex justify-between mb-2">
  <h3 class="headline-x-small">
    <span [innerText]="'Dashboard.CurrentParkingSessions' | translatePlural: { count: openParkingSessions.length }"></span>
  </h3>
  <div class="flex items-center body-medium gap-1" *ngIf="openParkingSessions.length > 1">
    <div class="cursor-pointer px-0.5" [class.opacity-35]="currentIndex === 0" (click)="previousParkingSession()">
      <i class="fa-light fa-chevron-left"></i>
    </div>
    <div>
      <span [innerText]="currentIndex + 1"></span>
      <span>/</span>
      <span [innerText]="openParkingSessions.length"></span>
    </div>
    <div class="cursor-pointer px-0.5" [class.opacity-35]="currentIndex === openParkingSessions.length - 1" (click)="nextParkingSession()">
      <i class="fa-light fa-chevron-right"></i>
    </div>
  </div>
</div>

@if (openParkingSessions.length > 0) {
  <!-- Mobile -->
  <div class="lg:hidden xl:block 2xl:hidden">
    <arivo-accordion class="neutral">
      <arivo-accordion-header class="neutral">
        <div class="flex items-start gap-4 py-1 xl:pb-1.5">
          <div class="hidden sm:flex items-center text-4xl">
            <i
              class="fa-kit fa-parking mt-1"
              [ngClass]="{
                'text-decoration': openParkingSessions[currentIndex].type === 'ppu',
                'text-success-dark': openParkingSessions[currentIndex].type === 'contract',
              }"
            ></i>
          </div>
          <div class="flex-grow flex items-center justify-between gap-4">
            <div>
              <h3 class="body-large mb-0.5" [innerText]="openParkingSessions[currentIndex].garage_name"></h3>
              <p
                *ngIf="openParkingSessions[currentIndex].type"
                class="body-small"
                [innerText]="'Dashboard.OpenParkingType.' + openParkingSessions[currentIndex].type | translate"
              ></p>
            </div>
            <div class="flex-grow text-right">
              <h4
                class="body-large text-interaction whitespace-nowrap mb-0.5"
                [innerText]="
                  openParkingSessions[currentIndex].price.amount | localizedCurrency: openParkingSessions[currentIndex].price.currency
                "
              ></h4>
              <p class="flex items-center justify-end body-small whitespace-nowrap">
                <i class="fa-kit fa-time mr-1"></i>
                <span [innerHtml]="openParkingSessions[currentIndex].duration_in_seconds | duration: 'seconds'"></span>
              </p>
            </div>
          </div>
        </div>
      </arivo-accordion-header>
      <arivo-accordion-body class="neutral">
        <div class="flex flex-wrap gap-4">
          <div class="flex-1">
            <arivo-license-plate
              [country]="openParkingSessions[currentIndex].license_plate.license_plate_country"
              [licensePlate]="openParkingSessions[currentIndex].license_plate.license_plate"
            />
          </div>
          <div class="flex-1">
            <h4 class="body-large" [innerText]="openParkingSessions[currentIndex].start_time | localizedDate: 'short'"></h4>
            <p class="body-small" [innerText]="'Dashboard.Entry' | translate"></p>
          </div>
        </div>
      </arivo-accordion-body>
    </arivo-accordion>
  </div>

  <!-- Desktop -->
  <div class="hidden lg:block xl:hidden 2xl:block">
    <div class="card card-small card-horizontal">
      <div class="card-body">
        <div class="flex items-start gap-4">
          <div class="flex items-center text-4xl">
            <i
              class="fa-kit fa-parking mt-1"
              [ngClass]="{
                'text-decoration': openParkingSessions[currentIndex].type === 'ppu',
                'text-success-dark': openParkingSessions[currentIndex].type === 'contract',
              }"
            ></i>
          </div>
          <div class="flex-grow">
            <h3 class="body-large" [innerText]="openParkingSessions[currentIndex].garage_name"></h3>
            <p *ngIf="openParkingSessions[currentIndex].type" class="body-medium">
              <span
                class="text-neutral-50"
                [innerText]="'Dashboard.OpenParkingType.' + openParkingSessions[currentIndex].type | translate"
              ></span>
            </p>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="flex flex-wrap gap-4 text-right">
          <div class="flex-1">
            <arivo-license-plate
              class="block mb-2"
              [country]="openParkingSessions[currentIndex].license_plate.license_plate_country"
              [licensePlate]="openParkingSessions[currentIndex].license_plate.license_plate"
            />

            <h4 class="body-large" [innerText]="openParkingSessions[currentIndex].start_time | localizedDate: 'short'"></h4>
            <p class="body-small" [innerText]="'Dashboard.Entry' | translate"></p>
          </div>
          <div class="flex-1">
            <h4
              class="body-large text-interaction"
              [innerText]="
                openParkingSessions[currentIndex].price.amount | localizedCurrency: openParkingSessions[currentIndex].price.currency
              "
            ></h4>
            <p class="body-small mb-2" [innerText]="'Dashboard.Costs' | translate"></p>

            <h4 class="body-large" [innerHtml]="openParkingSessions[currentIndex].duration_in_seconds | duration: 'seconds'"></h4>
            <p class="body-small" [innerText]="'Dashboard.Duration' | translate"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
} @else {
  <div class="text-center text-2xl text-neutral-50 mb-1 xl:mt-6">
    <i class="fa-kit fa-parking-space"></i>
  </div>
  <p class="body-medium text-center">
    <span class="text-neutral-50" [innerText]="'Dashboard.CurrentParkingSessions' | translatePlural: { count: 0 }"></span>
  </p>
}
