import { Component, OnInit } from '@angular/core';

@Component({
  template: '',
})
export class ImprintRedirectComponent implements OnInit {
  ngOnInit() {
    window.location.href = 'https://www.wipark.at/unternehmen/impressum';
  }
}
