import { parseISO } from 'date-fns';
import { MonoTypeOperatorFunction, tap } from 'rxjs';
import { LoadingService } from '../../core/services/loading.service';

/**
 * Tries best to parse the given date. It supports
 * - numbers -> unix timestamp (UTC)
 * - strings -> ISO 8601 with timezone support (Z or +0100)
 * - Date objects
 * - undefined/null/"" -> undefined
 *
 * NOTE: If not timezone information is given in string dates, it is assumed to be UTC.
 */
export function parseDate(date: string | number | Date): Date;
export function parseDate(date: string | number | Date | null | undefined): Date | undefined;
export function parseDate(date: any): Date | undefined {
  if (date === undefined || date === null) {
    return undefined;
  }

  // Check if the date is a string and matches the format YYYY-MM-DD
  if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
    // Append "T00:00:00" to make it a full ISO 8601 date string
    date += 'T00:00:00';
  }

  return parseISO(date);
}

export function showSpinner<T>(): MonoTypeOperatorFunction<T> {
  return (source) => {
    return source.pipe(
      tap({
        subscribe: () => {
          LoadingService._instance.setLoading(true);
        },
        finalize: () => {
          LoadingService._instance.setLoading(false);
        },
      }),
    );
  };
}
