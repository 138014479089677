import { HttpClient } from '@angular/common/http';
import { AssetHashes, AssetHashesService } from '../services/asset-hashes.service';
import { environment } from '../../../environments/environment';
import { Observable, of, switchMap } from 'rxjs';
import { LanguageService } from '../services/language.service';

export function initializeAppFactory(httpClient: HttpClient, assetHashesService: AssetHashesService, languageService: LanguageService) {
  return () => {
    let downloadAssetHashesSubscription: Observable<any> | undefined = undefined;
    if (environment.production) {
      if ((window as any).ASSET_HASHES) {
        downloadAssetHashesSubscription = of((window as any).ASSET_HASHES);
      } else {
        downloadAssetHashesSubscription = httpClient.get(assetHashesService.getAssetHashesUrl());
      }
    } else {
      downloadAssetHashesSubscription = of(<AssetHashes>{});
    }

    return downloadAssetHashesSubscription.pipe(
      switchMap((res: any) => {
        assetHashesService.setAssetHashes(res);
        return languageService.registerAndInitializeLocales();
      }),
    );
  };
}
