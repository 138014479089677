export const environment = {
  name: 'staging',
  production: true,
  publicUrl: 'https://wipark.digimon.arivo.fun/',
  sentryDsn: undefined,
  mockCalls: true,
  adyenClientKey: 'test_RERCPHYF55DLBA5YIATRQISQZQ27OK35',
  firebaseConfig: {
    apiKey: 'AIzaSyAvTe54WZRMr3K_Xz4sMWuK0UpgEEwpZmg',
    authDomain: 'arivofun-digimon-arivo.firebaseapp.com',
    projectId: 'arivofun-digimon-arivo',
    storageBucket: 'arivofun-digimon-arivo.appspot.com',
    messagingSenderId: '297262947086',
    appId: '1:297262947086:web:883d751bab64cd17a1d55f',
  },
  vapidKey: 'BItrnJF1ZDCcjeyxNxC3w1lEnKPVxLhQls65ATNmPZsWzH_FKqKMHWcNoOFubZasOHX0r5vjlrSBoP8lEPix0qY',
  demoLoginOnly: false,
};
