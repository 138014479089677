import { Pipe, PipeTransform } from '@angular/core';
import { AssetHashesService } from '../services/asset-hashes.service';

export type UrlType = 'asset';

@Pipe({
  name: 'url',
})
export class UrlPipe implements PipeTransform {
  constructor(private _assetHashes: AssetHashesService) {}

  transform(url: string): string {
    return this._assetHashes.getHashedAssetUrl(url);
  }
}
