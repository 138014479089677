import { CanActivateFn, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';

export const checkTokenValidity: CanActivateFn = (): Observable<boolean | UrlTree> => {
  return of(!!localStorage.getItem('token'));
};

export const checkTokenInvalidity: CanActivateFn = (): Observable<boolean | UrlTree> => {
  return of(!localStorage.getItem('token'));
};
