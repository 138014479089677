import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePluralPipe } from '../../core/pipes/translate-plural.pipe';

@Pipe({
  name: 'duration',
  pure: false,
})
export class DurationPipe implements PipeTransform {
  constructor(private translatePluralPipe: TranslatePluralPipe) {}

  private convertFromMinutes(value: number, unit: 'hours' | 'minutes' | 'seconds'): number {
    if (unit === 'hours') {
      return value * 60;
    } else if (unit === 'seconds') {
      return value / 60;
    }
    return value;
  }

  private formatDurationPart(count: number, singularKey: string, pluralKey: string, format: 'short' | 'long'): string {
    const key = format === 'long' ? singularKey : pluralKey;
    return this.translatePluralPipe.transform(key, { count }).replace(' ', '&nbsp;');
  }

  transform(value: number, unit: 'hours' | 'minutes' | 'seconds' = 'minutes', format: 'short' | 'long' = 'long'): string {
    if (value === 0) {
      value = 1;
    }

    if (!value) {
      return '';
    }

    value = this.convertFromMinutes(value, unit);

    const minutesInYear = 525600;
    const minutesInDay = 1440;
    const minutesInHour = 60;

    const years = Math.floor(value / minutesInYear);
    value %= minutesInYear;

    const days = Math.floor(value / minutesInDay);
    value %= minutesInDay;

    const hours = Math.floor(value / minutesInHour);
    const minutes = Math.ceil(value % minutesInHour);

    const durationParts = [];
    if (years > 0) {
      durationParts.push(this.formatDurationPart(years, 'Common.Years', 'Common.Yrs', format));
    }
    if (days > 0) {
      durationParts.push(this.formatDurationPart(days, 'Common.Days', 'Common.Days', format));
    }
    if (hours > 0) {
      durationParts.push(this.formatDurationPart(hours, 'Common.Hours', 'Common.Hrs', format));
    }
    if (minutes > 0) {
      durationParts.push(this.formatDurationPart(minutes, 'Common.Minutes', 'Common.Mins', format));
    }

    return durationParts.join(' ');
  }
}
