import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translatePlural',
  pure: false,
})
export class TranslatePluralPipe implements PipeTransform {
  constructor(public translateService: TranslateService) {}

  transform(query: string, params: any): string {
    const count = params ? params.count : null;

    if (count === 0) {
      return this.translateService.instant(`${query}.None`, params);
    } else if (count === 1) {
      return this.translateService.instant(`${query}.Singular`, params);
    } else if (count > 1) {
      return this.translateService.instant(`${query}.Plural`, params);
    } else {
      return this.translateService.instant(query, params);
    }
  }
}
