@for (dialog of dialogues; track $index) {
  <arivo-dialog [open]="true" [dismissible]="false" *ngIf="$index === 0">
    <div class="card">
      <div class="card-header">
        <div class="inline-flex items-center">
          <div>
            <i
              class="text-4xl fa-light mr-5"
              [ngClass]="{
                'text-success-dark fa-circle-check': dialog.type === 'success',
                'text-interaction-dark fa-circle-info': dialog.type === 'info',
                'text-warning': dialog.type === 'warning',
                'text-error': dialog.type === 'error',
                'fa-triangle-exclamation': dialog.type === 'warning' || dialog.type === 'error',
              }"
            ></i>
          </div>

          <div>
            <p
              class="headline-small"
              [ngClass]="{
                'text-success-dark': dialog.type === 'success',
                'text-interaction-dark': dialog.type === 'info',
                'text-warning': dialog.type === 'warning',
                'text-error': dialog.type === 'error',
              }"
            >
              {{ dialog.title }}
            </p>
            <p
              class="body-large"
              [ngClass]="{
                'text-success-dark': dialog.type === 'success',
                'text-interaction-dark': dialog.type === 'info',
                'text-warning': dialog.type === 'warning',
                'text-error': dialog.type === 'error',
              }"
            >
              {{ dialog.subtitle }}
            </p>
          </div>
        </div>
        <div></div>
      </div>
      <div class="card-body">
        <p class="body-large" [innerHtml]="dialog.content"></p>
      </div>
      <div class="card-footer">
        <div class="inline-flex justify-between w-full py-1">
          <button
            *ngIf="dialog.buttonText"
            [ngClass]="{
              'button-success-outlined': dialog.type === 'success',
              'button-primary-outlined': dialog.type === 'info',
              'button-warning-outlined': dialog.type === 'warning',
              'button-danger-outlined': dialog.type === 'error',
            }"
            (click)="handleButtonClick($index)"
          >
            {{ dialog.buttonText }}
          </button>
          <button
            *ngIf="dialog.dismissible"
            class="button-primary-text"
            (click)="handleDismiss($index)"
            [innerText]="dialog.dismissButtonText ? dialog.dismissButtonText : ('Dialog.Understood' | translate)"
          ></button>
        </div>
      </div>
    </div>
  </arivo-dialog>
}
