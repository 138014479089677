<h3 class="flex items-baseline headline-x-small mb-2">
  <i class="fa-kit fa-time"></i>
  <div class="flex flex-wrap pl-2">
    <span>
      <span [innerText]="'Dashboard.ParkingBasedOnTime' | translate"></span>
      <span>:&nbsp;</span>
    </span>
    <span class="font-light" [innerText]="'Dashboard.ShortTermParking' | translate"></span>
  </div>
</h3>

<arivo-accordion class="mb-2" [open]="true">
  <arivo-accordion-header>
    <div class="flex gap-4">
      <div class="text-4xl text-decoration">
        <i class="fa-kit fa-parking mt-1"></i>
      </div>
      <div>
        <h5 class="body-large" [innerText]="'Dashboard.FlexibleParkingInAllCarParks' | translate: { count: garageAmount }"></h5>
        <p
          class="body-medium text-neutral-50"
          [innerText]="'Dashboard.VehiclesAllowed' | translate: { allowed: enabledLicensePlates, total: licensePlates.length }"
        ></p>
      </div>
    </div>
  </arivo-accordion-header>
  <arivo-accordion-body>
    <div class="flex flex-wrap justify-between gap-4">
      <div class="flex flex-wrap gap-6">
        @for (licensePlate of licensePlates; track licensePlate) {
          <div class="flex items-center gap-2">
            <input
              type="checkbox"
              class="switch"
              [checked]="licensePlate.enabled"
              (click)="$event.preventDefault(); onPayPerUseCheckboxClicked(licensePlate)"
            />
            <arivo-license-plate
              [country]="licensePlate.license_plate_country"
              [licensePlate]="licensePlate.license_plate"
              [checkInvalid]="true"
            />
          </div>
        }
      </div>
      @if (!showLicensePlateForm) {
        <button class="button-primary-text button-small mt-1" (click)="showLicensePlateForm = true">
          <i class="fa-light fa-plus-large mr-1"></i>
          <span [innerText]="'Common.AddVehicle' | translate"></span>
        </button>
      }
    </div>
    @if (showLicensePlateForm) {
      <div class="well mt-4 2xl:max-w-[1000px] lg:max-w-[750px] w-auto ml-auto mr-auto">
        <form [formGroup]="licensePlateForm" (ngSubmit)="addLicensePlate()">
          <div class="xl:flex xl:justify-between">
            <div class="ar-input-group flex-shrink w-full">
              <arivo-license-plate-input class="flex-1" formControlName="licensePlate" [inline]="true" [showErrors]="false" />
              <p class="error" *formError="'licensePlate.required'" [innerText]="'Common.Errors.LicensePlateIsRequired' | translate"></p>
              <p
                class="error"
                *formError="'licensePlate.unique'"
                [innerText]="
                  'Common.Errors.VehicleAlreadyAllowedForShortTermParking'
                    | translate
                      : {
                          licensePlate: formatLicensePlate(licensePlateForm.value.licensePlate!.license_plate),
                        }
                "
              ></p>
            </div>
            <div class="max-xl:flex max-xl:flex-wrap max-xl:mt-3 xl:inline-flex items-center justify-end gap-4 ml-5 mt-2">
              <button
                class="button-primary-text button-small mb-auto"
                type="button"
                (click)="showLicensePlateForm = false"
                [innerText]="'Common.Cancel' | translate"
              ></button>
              <button class="button-primary button-small mb-auto" type="submit" [innerText]="'Common.Add' | translate"></button>
            </div>
          </div>
        </form>
      </div>
    }
  </arivo-accordion-body>
</arivo-accordion>
<p class="body-medium mb-1" [innerText]="'Dashboard.TimeBasedDescription' | translate"></p>
<p class="body-medium" [innerText]="'Dashboard.TimeBasedInfo' | translate"></p>
