import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { LoadingService } from './core/services/loading.service';
import { RouterService } from './shared/services/router.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { PwaService } from './shared/services/pwa.service';
import firebase from 'firebase/compat';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  loading: boolean = false;

  constructor(
    private routerService: RouterService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    private pwaService: PwaService,
    private angularFireMessaging: AngularFireMessaging,
  ) {}

  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(e: Event) {
    e.preventDefault();
    this.pwaService.homeScreenDeferredPrompt = e;
  }

  ngOnInit() {
    this.loadingService.loadingChanges().subscribe((loading) => {
      this.loading = loading;
      this.cdr.detectChanges();
    });

    this.angularFireMessaging.messages.subscribe((message: firebase.messaging.MessagePayload) => {
      // TODO: Set fallback notification title
      new Notification(message.notification?.title ?? 'Arivo', {
        body: message.notification?.body,
      });
    });
  }
}
