import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type Dialog = {
  title: string;
  subtitle?: string;
  content: string;
  dismissible?: boolean;
  buttonText?: string;
  buttonRoute?: {
    link: string;
    queryParams?: { [key: string]: string | null };
  };
  dismissButtonText?: string;
  type: 'success' | 'info' | 'warning' | 'error';
};

@Injectable({
  providedIn: 'root',
})
export class ArivoDialogService {
  dialogues: BehaviorSubject<Dialog[]> = new BehaviorSubject<Dialog[]>([]);

  addDialog(dialog: Dialog): void {
    this.dialogues.next([...this.dialogues.value, dialog]);
  }

  removeDialog(index: number): void {
    const dialogs = this.dialogues.value;
    dialogs.splice(index, 1);
    this.dialogues.next(dialogs);
  }

  constructor() {}
}
