import { Component, Input } from '@angular/core';

@Component({
  selector: 'arivo-tab',
  templateUrl: './arivo-tab.component.html',
  styleUrl: './arivo-tab.component.scss',
})
export class ArivoTabComponent {
  @Input() title: string = '';
  @Input() icon: string = '';
  @Input() active = false;
  @Input() urlParamKey: string | undefined = undefined;
}
