<div class="flex items-center gap-1 body-medium">
  <div [ngClass]="{ 'cursor-pointer': currentPage !== 1 }" class="p-1.5" (click)="previousPage()">
    <i class="fa-light fa-chevron-left" [class.opacity-35]="currentPage === 1"></i>
  </div>

  <div class="whitespace-nowrap w-20 select-none flex justify-center items-center">
    <span [innerText]="start"></span>
    <span>&ndash;</span>
    <span [innerText]="end"></span>
    <span> / </span>
    <span [innerText]="totalCount"></span>
  </div>

  <div [ngClass]="{ 'cursor-pointer': currentPage !== totalPages }" class="p-1.5" (click)="nextPage()">
    <i class="fa-light fa-chevron-right" [class.opacity-35]="currentPage === totalPages"></i>
  </div>
</div>
