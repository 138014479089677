import { AfterViewInit, Component, DestroyRef, Inject, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { BillingResolve } from '../../../shared/models/routeTyping';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ArivoPaymentMethod, Money } from '../../../shared/models/common';
import { ArivoPaymentService } from '../../../shared/components/arivo-payment-container/arivo-payment.service';
import { ArivoPaymentContainerComponent } from '../../../shared/components/arivo-payment-container/arivo-payment-container.component';

@Component({
  selector: 'app-billing-account-balance',
  templateUrl: './billing-account-balance.component.html',
  styleUrl: './billing-account-balance.component.scss',
})
export class BillingAccountBalanceComponent implements OnInit, AfterViewInit {
  @ViewChild('paymentContainer') private _paymentContainer?: ArivoPaymentContainerComponent;
  private _destroyRef = inject(DestroyRef);
  paymentMethod: ArivoPaymentMethod | null = null;
  openCosts: Money[] = [];
  creditCardIconClass: string | null = null;

  constructor(
    @Inject(ActivatedRoute) private route: TypedRoute<BillingResolve>,
    private router: Router,
    private paymentService: ArivoPaymentService,
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((data) => {
      this.openCosts = data.billingApiResponse.open_costs;
      this.paymentMethod = data.billingApiResponse.payment_method;
      if (this.paymentMethod?.brand) {
        this.creditCardIconClass = this.paymentService.getCreditCardIcon(this.paymentMethod.brand);
      }
    });
    this.paymentService.activePaymentMethod.subscribe((paymentMethod) => {
      if (paymentMethod) {
        this.paymentMethod = paymentMethod;
        if (this.paymentMethod?.brand) {
          this.creditCardIconClass = this.paymentService.getCreditCardIcon(this.paymentMethod.brand);
        }
      }
    });
  }

  ngAfterViewInit() {
    this.route.queryParamMap.subscribe((params) => {
      const showPaymentFormStatus = params.get('showPaymentForm');
      if (showPaymentFormStatus === 'true') {
        this._paymentContainer!.setUpComponents();
      }
    });
  }

  removeShowPaymentFormParam(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { showPaymentForm: null },
      queryParamsHandling: 'merge',
    });
  }
}
