import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnboardingFinishResponse } from '../../../shared/models/routeTyping';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OnboardingFinishApiService {
  constructor(private http: HttpClient) {}

  getOnboardingResponse(): Observable<OnboardingFinishResponse> {
    return this.http.get<OnboardingFinishResponse>(`/api/onboarding`);
  }
}
