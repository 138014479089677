<arivo-alerts></arivo-alerts>

<div class="flex items-center justify-between gap-4 mb-4">
  <div class="headline-small" [innerText]="'CarParks.CarParksTariffs' | translate"></div>
  <div class="flex items-center gap-2 lg:hidden">
    <button class="button-circle" (click)="display = 'map'" [class.selected]="display === 'map'">
      <i class="fa-light fa-location-dot"></i>
    </button>
    <button class="button-circle" (click)="display = 'list'" [class.selected]="display === 'list'">
      <i class="fa-kit fa-list"></i>
    </button>
  </div>
</div>

@if (displayUserLocationWarning) {
  <div class="alert mb-4">
    <div class="text-2xl icon">
      <i class="fa-light fa-triangle-exclamation"></i>
    </div>
    <span class="body-medium" [innerText]="'CarParks.EnableLocationServices' | translate"></span>
  </div>
}

<div class="map-wrapper">
  <div class="map flex-grow lg:block" [ngClass]="display === 'map' ? 'block' : 'hidden'">
    <div id="map" class="map"></div>
  </div>
  <div class="map-panel lg:block" [ngClass]="display === 'list' ? 'block' : 'hidden'">
    <div class="flex flex-col">
      @for (marker of mapMarkers; track marker) {
        <div
          class="garage-list-item"
          [class.bg-decoration-transparent-8]="marker === selectedMapMarker"
          (click)="updateSelectedMarker(marker, true)"
        >
          <div class="garage-list-item-content">
            <div class="text-2xl text-decoration">
              <i class="fa-kit fa-parking"></i>
            </div>
            <div>
              <div class="body-large mb-0.5" [innerText]="marker.name"></div>
              <div class="body-medium">
                <span class="text-neutral-50" [innerText]="formatAddress(marker)"></span>
              </div>
              @if (userLocation) {
                <div class="body-medium mt-2">
                  <span class="text-neutral-50" [innerText]="formatDistance(distanceToUser(marker))"></span>
                </div>
              }
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>

<!-- Mobile -->
<div class="garage-details-dialog">
  <arivo-bottom-sheet-dialog #garageDetailsDialog [open]="selectedMapMarker !== undefined" (onClose)="updateSelectedMarker(undefined)">
    <app-garage-details [garage]="selectedMapMarker" />
  </arivo-bottom-sheet-dialog>
</div>

<!-- Desktop -->
<div class="garage-details mt-4">
  <app-garage-details [garage]="selectedMapMarker" />
</div>
