<app-header />
<div class="flex">
  <app-sidebar />
  <div class="min-w-0 mx-auto flex justify-center">
    <div class="content pb-5">
      <router-outlet />
    </div>
  </div>
</div>

<arivo-dialog-container />
