// Angular imports
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Third-party libraries
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { MatIconModule } from '@angular/material/icon';
import localeDe from '@angular/common/locales/de';

// Factories
import { createTranslateLoader } from './utils/translate-loader.factory';
import { initializeAppFactory } from './utils/app-initializer.factory';

// Services
import { AssetHashesService } from './services/asset-hashes.service';
import { LanguageService } from './services/language.service';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { MockHttpInterceptor } from './dev/mock-http-interceptor.service';

// Components
// Pipes
import { UrlPipe } from './pipes/url.pipe';
import { TranslatePluralPipe } from './pipes/translate-plural.pipe';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { LocalizedCurrencyPipe } from './pipes/localized-currency.pipe';

registerLocaleData(localeDe);

@NgModule({
  declarations: [UrlPipe, TranslatePluralPipe, LocalizedDatePipe, LocalizedCurrencyPipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, AssetHashesService],
      },
    }),
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
    BrowserAnimationsModule,
    MatIconModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
    UrlPipe,
    TranslatePluralPipe,
    LocalizedDatePipe,
    LocalizedCurrencyPipe,
  ],
  providers: [
    UrlPipe,
    DatePipe,
    DecimalPipe,
    CurrencyPipe,
    TranslatePluralPipe,
    LocalizedDatePipe,
    LocalizedCurrencyPipe,
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR',
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MockHttpInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      deps: [HttpClient, AssetHashesService, LanguageService],
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CoreModule {}
