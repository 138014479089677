import { LicensePlateCountry } from '../models/country';

export const licensePlateCountries: LicensePlateCountry[] = [
  {
    iso2Code: 'AT',
    lprCode: 'A',
    name: 'Austria',
    placeholder: 'W:12345A',
    euCountry: true,
    firstPartLength: 2,
  },
  {
    iso2Code: 'DE',
    lprCode: 'D',
    name: 'Germany',
    placeholder: 'FLR:AB1234',
    euCountry: true,
    firstPartLength: 3,
  },
  {
    iso2Code: 'CH',
    lprCode: 'CH',
    name: 'Switzerland',
    placeholder: 'ZH:12345',
    euCountry: false,
    firstPartLength: 2,
  },
  {
    iso2Code: 'BE',
    lprCode: 'B',
    name: 'Belgium',
    placeholder: '1ABC123',
    euCountry: true,
  },
  {
    iso2Code: 'BG',
    lprCode: 'BG',
    name: 'Bulgaria',
    placeholder: 'PA1234AX',
    euCountry: true,
  },
  {
    iso2Code: 'BA',
    lprCode: 'BIH',
    name: 'Bosnia and Herzegovina',
    placeholder: 'ABC123',
    euCountry: false,
  },
  {
    iso2Code: 'CY',
    lprCode: 'CY',
    name: 'Cyprus',
    placeholder: 'ABC123',
    euCountry: true,
  },
  {
    iso2Code: 'CZ',
    lprCode: 'CZ',
    name: 'Czech Republic',
    placeholder: '1AB:1234',
    euCountry: true,
    firstPartLength: 3,
  },
  {
    iso2Code: 'DK',
    lprCode: 'DK',
    name: 'Denmark',
    placeholder: 'AB12345',
    euCountry: true,
  },
  {
    iso2Code: 'ES',
    lprCode: 'E',
    name: 'Spain',
    placeholder: '1234BCD',
    euCountry: true,
  },
  {
    iso2Code: 'EE',
    lprCode: 'EST',
    name: 'Estonia',
    placeholder: '123ABC',
    euCountry: true,
  },
  {
    iso2Code: 'FR',
    lprCode: 'F',
    name: 'France',
    placeholder: 'AB123AB',
    euCountry: true,
  },
  {
    iso2Code: 'FI',
    lprCode: 'FIN',
    name: 'Finland',
    placeholder: 'ABC123',
    euCountry: true,
  },
  {
    iso2Code: 'GB',
    lprCode: 'GB',
    name: 'United Kingdom',
    placeholder: 'AB12ABC',
    euCountry: false,
  },
  {
    iso2Code: 'GR',
    lprCode: 'GR',
    name: 'Greece',
    placeholder: 'ABC1234',
    euCountry: true,
  },
  {
    iso2Code: 'HU',
    lprCode: 'H',
    name: 'Hungary',
    placeholder: 'ABC123',
    euCountry: true,
  },
  {
    iso2Code: 'HR',
    lprCode: 'HR',
    name: 'Croatia',
    placeholder: 'ZG:1234CD',
    euCountry: true,
    firstPartLength: 2,
  },
  {
    iso2Code: 'IT',
    lprCode: 'I',
    name: 'Italy',
    placeholder: 'AB123AB',
    euCountry: true,
  },
  {
    iso2Code: 'IE',
    lprCode: 'IRL',
    name: 'Ireland',
    placeholder: '131AB12345',
    euCountry: true,
  },
  {
    iso2Code: 'LU',
    lprCode: 'L',
    name: 'Luxembourg',
    placeholder: 'AB1234',
    euCountry: true,
  },
  {
    iso2Code: 'LT',
    lprCode: 'LT',
    name: 'Lithuania',
    placeholder: 'ABC23',
    euCountry: true,
  },
  {
    iso2Code: 'LV',
    lprCode: 'LV',
    name: 'Latvia',
    placeholder: 'AB1234',
    euCountry: true,
  },
  {
    iso2Code: 'MC',
    lprCode: 'MC',
    name: 'Monaco',
    placeholder: 'ABC123',
    euCountry: false,
  },
  {
    iso2Code: 'MD',
    lprCode: 'MD',
    name: 'Moldova',
    placeholder: 'ABC123',
    euCountry: false,
  },
  {
    iso2Code: 'ME',
    lprCode: 'MNE',
    name: 'Montenegro',
    placeholder: 'AB:C123',
    euCountry: false,
    firstPartLength: 2,
  },
  {
    iso2Code: 'NO',
    lprCode: 'N',
    name: 'Norway',
    placeholder: 'ABC:123',
    euCountry: false,
    firstPartLength: 2,
  },
  {
    iso2Code: 'NL',
    lprCode: 'NL',
    name: 'Netherlands',
    placeholder: '12ABC3',
    euCountry: true,
  },
  {
    iso2Code: 'PL',
    lprCode: 'PL',
    name: 'Poland',
    placeholder: 'ABC:12AB',
    euCountry: true,
    firstPartLength: 3,
  },
  {
    iso2Code: 'PT',
    lprCode: 'P',
    name: 'Portugal',
    placeholder: '12AB34',
    euCountry: true,
  },
  {
    iso2Code: 'RO',
    lprCode: 'RO',
    name: 'Romania',
    placeholder: 'AB:12ABC',
    euCountry: true,
    firstPartLength: 2,
  },
  {
    iso2Code: 'RU',
    lprCode: 'RUS',
    name: 'Russia',
    placeholder: 'ABC123',
    euCountry: false,
  },
  {
    iso2Code: 'SE',
    lprCode: 'S',
    name: 'Sweden',
    placeholder: 'ABC123',
    euCountry: true,
  },
  {
    iso2Code: 'SK',
    lprCode: 'SK',
    name: 'Slovakia',
    placeholder: 'AB:123AB',
    euCountry: true,
    firstPartLength: 2,
  },
  {
    iso2Code: 'SI',
    lprCode: 'SLO',
    name: 'Slovenia',
    placeholder: 'LJ:AB123',
    euCountry: true,
    firstPartLength: 2,
  },
  {
    iso2Code: 'RS',
    lprCode: 'SRB',
    name: 'Serbia',
    placeholder: 'AB:C123',
    euCountry: false,
    firstPartLength: 2,
  },
  {
    iso2Code: 'UA',
    lprCode: 'UA',
    name: 'Ukraine',
    placeholder: 'ABC123',
    euCountry: false,
  },
  {
    iso2Code: 'XX',
    lprCode: 'UNKNOWN',
    name: 'Unknown',
    placeholder: 'ABC123',
    euCountry: false,
  },
];
