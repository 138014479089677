import { Component } from '@angular/core';

@Component({
  selector: 'app-data-privacy',
  templateUrl: './data-privacy.component.html',
  styleUrl: './data-privacy.component.scss'
})
export class DataPrivacyComponent {

}
