import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProductsApiResponse } from '../../shared/models/routeTyping';

@Injectable({
  providedIn: 'root',
})
export class ProductsApiService {
  constructor(private http: HttpClient) {}

  getProductsResponse(): Observable<ProductsApiResponse> {
    return this.http.get<ProductsApiResponse>(`/api/products`);
  }
}
