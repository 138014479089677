import { Component, ElementRef, Host } from '@angular/core';
import { ArivoAccordionComponent } from '../arivo-accordion.component';

@Component({
  selector: 'arivo-accordion-body',
  templateUrl: './arivo-accordion-body.component.html',
  styleUrl: './arivo-accordion-body.component.scss',
})
export class ArivoAccordionBodyComponent {
  private _timeoutId: any;

  constructor(
    @Host() private _accordion: ArivoAccordionComponent,
    private _elementRef: ElementRef,
  ) {}

  toggleOpen(): void {
    const element = this._elementRef?.nativeElement;
    if (element) {
      if (this._timeoutId) {
        clearTimeout(this._timeoutId);
      }

      if (this._accordion.open) {
        element.style.height = element.scrollHeight + 'px';

        this._timeoutId = setTimeout(() => {
          element.style.height = '0';
        });
      } else {
        element.style.height = element.scrollHeight + 'px';

        this._timeoutId = setTimeout(() => {
          element.style.height = 'auto';
        }, 300);
      }
    }
  }

  open(): void {
    const element = this._elementRef?.nativeElement;
    if (element) {
      element.style.height = 'auto';
    }
  }
}
