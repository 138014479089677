import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient) {}

  sendLoginEmail(email: string): Observable<any> {
    return this.http.post(`/api/user/v2/auth/login/email/`, {
      email: email,
    });
  }
}
