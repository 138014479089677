import { Injectable } from '@angular/core';
import { LoadingService } from '../../core/services/loading.service';
import { ToastService } from './toast.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseApp } from '@angular/fire/compat';
import firebase from 'firebase/compat';
import { AssetHashesService } from '../../core/services/asset-hashes.service';

type NotificationStatus = 'unsupported' | 'denied' | 'granted' | 'default';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    private firebaseApp: FirebaseApp,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private assetHashesService: AssetHashesService,
  ) {
    if (this.notificationStatus === 'granted') {
      // Check if the token has previously been successfully retrieved from Firebase
      // If something is wrong, delete the database and try to retrieve the token
      const request = indexedDB.open('firebase-messaging-database');
      request.onsuccess = (event: Event) => {
        const db: IDBDatabase = (<any>event.target).result;

        if (db.objectStoreNames.contains('firebase-messaging-store')) {
          const transaction = db.transaction('firebase-messaging-store', 'readwrite');
          const firebaseMessagingStore = transaction.objectStore('firebase-messaging-store');

          const request = firebaseMessagingStore.get(environment.firebaseConfig.appId);
          request.onsuccess = (event: Event) => {
            const result = (<any>event.target).result;
            if (result && result.token) {
              // TODO: Decide if we need to do something with the token (e.g. send it to the backend if this previously failed)
              const token = result.token;
            } else {
              indexedDB.deleteDatabase('firebase-messaging-database');

              this.loadingService.setLoading(true);
              this.registerServiceWorker();
            }
          };
        } else {
          indexedDB.deleteDatabase('firebase-messaging-database');

          this.loadingService.setLoading(true);
          this.registerServiceWorker();
        }
      };
    }
  }

  private messaging: firebase.messaging.Messaging | null = this.firebaseApp.messaging ? this.firebaseApp.messaging() : null;

  notificationError: boolean = false;

  get notificationStatus(): NotificationStatus {
    return 'Notification' in window ? Notification.permission : 'unsupported';
  }

  activateNotifications() {
    // Request permission to show notifications
    if (this.notificationStatus === 'default') {
      this.loadingService.setLoading(true);

      Notification.requestPermission()
        .then((permission) => {
          if (permission === 'granted') {
            this.registerServiceWorker();
          } else {
            this.loadingService.setLoading(false);
          }
        })
        .catch(() => {
          this.toastService.add({
            title: this.translateService.instant('SignUp.NotificationsCouldNotBeEnabled'),
            message: this.translateService.instant('SignUp.NotificationsCouldNotBeEnabledDescription'),
            type: 'error',
          });
          this.loadingService.setLoading(false);
        });
    }
  }

  private registerServiceWorker() {
    navigator.serviceWorker
      .register(this.assetHashesService.getHashedAssetUrl('/firebase-messaging-sw.js'))
      .then((serviceWorkerRegistration) => {
        // Token can only be retrieved if the service worker is active
        // If the service worker is active, we retrieve the token; otherwise we wait for the service worker to become active
        let serviceWorker: ServiceWorker | null = null;
        if (serviceWorkerRegistration.active) {
          this.getToken(serviceWorkerRegistration);
        } else {
          if (serviceWorkerRegistration.installing) {
            serviceWorker = serviceWorkerRegistration.installing;
          } else if (serviceWorkerRegistration.waiting) {
            serviceWorker = serviceWorkerRegistration.waiting;
          }

          if (serviceWorker) {
            serviceWorker.addEventListener('statechange', (event: Event) => {
              if ((<any>event.target).state === 'activated') {
                this.getToken(serviceWorkerRegistration);
              }
            });
          } else {
            this.toastService.add({
              title: this.translateService.instant('SignUp.NotificationsCouldNotBeEnabled'),
              message: this.translateService.instant('SignUp.NotificationsCouldNotBeEnabledDescription'),
              type: 'error',
            });
            this.notificationError = true;
            this.loadingService.setLoading(false);
          }
        }
      })
      .catch(() => {
        this.toastService.add({
          title: this.translateService.instant('SignUp.NotificationsCouldNotBeEnabled'),
          message: this.translateService.instant('SignUp.NotificationsCouldNotBeEnabledDescription'),
          type: 'error',
        });
        this.notificationError = true;
        this.loadingService.setLoading(false);
      });
  }

  private getToken(serviceWorkerRegistration: ServiceWorkerRegistration) {
    if (this.messaging) {
      this.messaging
        .getToken({ vapidKey: environment.vapidKey, serviceWorkerRegistration: serviceWorkerRegistration })
        .then((token: string) => {
          // TODO: Send token to backend
          console.log(token);
        })
        .catch(() => {
          this.toastService.add({
            title: this.translateService.instant('SignUp.NotificationsCouldNotBeEnabled'),
            message: this.translateService.instant('SignUp.NotificationsCouldNotBeEnabledDescription'),
            type: 'error',
          });
          this.notificationError = true;
        })
        .finally(() => {
          this.loadingService.setLoading(false);
        });
    }
  }
}
