<arivo-alerts></arivo-alerts>

<div class="headline-small mb-4" [innerText]="'UserData.ProfileSettings' | translate"></div>
<form [formGroup]="contactDetailsForm" (ngSubmit)="handleSubmit()">
  <div class="card-responsive md:mb-4 mb-8">
    <div class="card-header">
      <h5 class="headline-x-small">
        <i class="max-md:text-2xl fa-kit fa-data"></i>
        <span [innerText]="'UserData.BillingAddress' | translate"></span>
      </h5>
    </div>
    <div class="card-body">
      <div class="ar-input-group mb-2">
        <div class="input-primary-container">
          <input id="email" class="input-primary" addEmptyPlaceholder formControlName="email" />
          <label for="email" [innerText]="'UserData.Email' | translate"></label>
        </div>
      </div>
      <div class="ar-input-group mb-2">
        <div class="input-primary-container">
          <input id="firstName" class="input-primary" addEmptyPlaceholder formControlName="first_name" />
          <label for="firstName" [innerText]="'UserData.FirstName' | translate"></label>
        </div>
        <p class="error text-left" *formError="'first_name.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
      </div>
      <div class="ar-input-group mb-2">
        <div class="input-primary-container">
          <input id="lastName" class="input-primary" addEmptyPlaceholder formControlName="last_name" />
          <label for="lastName" [innerText]="'UserData.LastName' | translate"></label>
        </div>
        <p class="error text-left" *formError="'last_name.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
      </div>
      <div class="ar-input-group mb-2">
        <div class="input-primary-container">
          <input id="address" class="input-primary" addEmptyPlaceholder formControlName="address" />
          <label for="address" [innerText]="'UserData.Address' | translate"></label>
        </div>
        <p class="error text-left" *formError="'address.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
      </div>
      <div class="ar-input-group mb-2">
        <div class="input-primary-container">
          <input id="postalCode" class="input-primary" addEmptyPlaceholder formControlName="zip_code" />
          <label for="postalCode" [innerText]="'UserData.PostalCode' | translate"></label>
        </div>
        <p class="error text-left" *formError="'zip_code.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
      </div>
      <div class="ar-input-group mb-2">
        <div class="input-primary-container">
          <input id="city" class="input-primary" addEmptyPlaceholder formControlName="city" />
          <label for="city" [innerText]="'UserData.City' | translate"></label>
        </div>
        <p class="error text-left" *formError="'city.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
      </div>
      <div class="ar-input-group mb-6">
        <div class="input-primary-container">
          <select id="country" class="input-primary" formControlName="country">
            @for (country of selectableCountries; track country.iso2Code) {
              <option
                [value]="country.iso2Code"
                [innerText]="'CountryNames.' + country.name | translate"
                *ngIf="country.iso2Code !== 'XX'"
              ></option>
            }
          </select>
          <label for="country" [innerText]="'UserData.Country' | translate"></label>
        </div>
        <p class="error text-left" *formError="'country.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
      </div>
      <div class="flex items-center gap-2">
        <input id="businessAccount" type="checkbox" class="switch" formControlName="business_account" />
        <label for="businessAccount" class="body-medium" [innerText]="'UserData.BusinessAccount' | translate"></label>
      </div>

      @if (contactDetailsForm.value.business_account) {
        <div class="ar-input-group mb-2 mt-6">
          <div class="input-primary-container">
            <input id="companyName" class="input-primary" formControlName="company_name" addEmptyPlaceholder />
            <label for="companyName" [innerText]="'UserData.CompanyName' | translate"></label>
          </div>
          <p class="error text-left" *formError="'company_name.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
        </div>
        <div class="ar-input-group mb-2">
          <div class="input-primary-container">
            <input id="uid" class="input-primary" addEmptyPlaceholder formControlName="uid" />
            <label for="uid" [innerText]="'UserData.UIDOptional' | translate"></label>
          </div>
        </div>
      }
    </div>
  </div>

  <arivo-step-footer>
    <button class="button-primary min-w-24" [innerText]="'Common.Save' | translate" type="submit"></button>
  </arivo-step-footer>
</form>

<app-settings class="block mt-8" />
