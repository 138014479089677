import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ArivoPaymentMethod } from '../../shared/models/common';

type CoreData = {
  payment_method_available: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class CoreDataService {
  coreData = new BehaviorSubject<CoreData>({
    payment_method_available: false,
  });

  constructor(private http: HttpClient) {}

  fetchCoreData(): Observable<CoreData> {
    return this.http.get<CoreData>(`/api/core-data`);
  }

  setCoreData(coreData: CoreData): void {
    this.coreData.next(coreData);
  }

  setPaymentMethodAvailability(paymentMethod: ArivoPaymentMethod): void {
    const currentData = this.coreData.value;
    this.coreData.next({ ...currentData, payment_method_available: !!paymentMethod });
  }
}
