import { Component } from '@angular/core';
import { PwaService } from '../../services/pwa.service';
import { NotificationsService } from '../../services/notifications.service';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-settings',
  templateUrl: './app-settings.component.html',
  styleUrl: './app-settings.component.scss',
})
export class AppSettingsComponent {
  constructor(
    private platform: Platform,
    public pwaService: PwaService,
    public notificationsService: NotificationsService,
  ) {}

  // Detects non-standalone mode on iOS and iPadOS devices
  get isIOS(): boolean {
    return this.platform.WEBKIT && this.notificationsService.notificationStatus === 'unsupported';
  }

  get isFirefoxMobile(): boolean {
    return this.platform.FIREFOX && this.platform.ANDROID;
  }
}
