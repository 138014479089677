import { AfterContentInit, Component, ContentChild, Input } from '@angular/core';
import { ArivoAccordionBodyComponent } from './arivo-accordion-body/arivo-accordion-body.component';

@Component({
  selector: 'arivo-accordion',
  templateUrl: './arivo-accordion.component.html',
  styleUrl: './arivo-accordion.component.scss',
})
export class ArivoAccordionComponent implements AfterContentInit {
  @ContentChild(ArivoAccordionBodyComponent) private _accordionBody?: ArivoAccordionBodyComponent;

  @Input() open: boolean = false;

  toggleOpen(): void {
    this._accordionBody?.toggleOpen();
    this.open = !this.open;
  }

  ngAfterContentInit() {
    if (this.open) {
      this._accordionBody?.open();
    }
  }
}
