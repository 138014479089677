import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { AssetHashesService } from '../services/asset-hashes.service';

function handleAssetHashesChanges(assetHashesService: AssetHashesService, httpClient: HttpClient, lang: string, subscriber: any): void {
  let isAssetHashesChangesSubscriptionExecuted: boolean = false;
  const assetHashesChangesSubscription: Subscription = assetHashesService.assetHashesChanges().subscribe({
    next: (assetHashes) => {
      if (!assetHashes || isAssetHashesChangesSubscriptionExecuted) {
        return;
      }

      isAssetHashesChangesSubscriptionExecuted = true;
      Promise.resolve().then(() => assetHashesChangesSubscription.unsubscribe());

      const langAssetUrl: string = `assets/i18n/${lang}.json`;
      httpClient.get(assetHashesService.getHashedAssetUrl(langAssetUrl)).subscribe({
        next: (res: any) => {
          subscriber.next(res);
          subscriber.complete();
        },
        error: (err) => {
          subscriber.error(err);
          subscriber.complete();
        },
      });
    },
  });
}

export const createTranslateLoader = (httpClient: HttpClient, assetHashesService: AssetHashesService): TranslateLoader => ({
  getTranslation: (lang: string) => {
    return new Observable<string>((subscriber) => {
      handleAssetHashesChanges(assetHashesService, httpClient, lang, subscriber);
    });
  },
});
