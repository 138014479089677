import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { checkLicensePlateFormat } from './license-plate.utils';
import { LPRCountryCode } from '../models/country';

/**
 * Some constants so we can more easily type angular typed forms
 */
export const fbOptionalString = <string | null>null;
export const fbString = <string>'';
export const fbOptionalNumber = <number | null>null;
export const fbNumber = <number>(<unknown>null);
export const fbOptionalBoolean = <boolean | null>null;
export const fbBoolean = <boolean>false;

export function requireLicensePlate(control: AbstractControl) {
  if (!control.value.license_plate) {
    return { required: true };
  }

  if (control.value.license_plate === ':' || control.value.license_plate_country === '') {
    return { required: true };
  }

  return null;
}

export const validateLicensePlate: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const licensePlate = control.value['licensePlate'] as string;
  const licensePlateCountry = control.value['licensePlateCountry'] as LPRCountryCode;
  if (!licensePlate || !licensePlateCountry) return { required: true };

  return !checkLicensePlateFormat(licensePlate, licensePlateCountry) ? { invalidFormat: true } : null;
};
