import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Toast, ToastService } from '../../services/toast.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-arivo-toast',
  templateUrl: './arivo-toast.component.html',
  styleUrls: ['./arivo-toast.component.scss'],
  animations: [
    trigger('toastAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0.5s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [animate('0.0s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ArivoToastComponent implements OnInit {
  private _destroyRef = inject(DestroyRef);
  toasts: Toast[] = [];

  constructor(private toastService: ToastService) {}

  ngOnInit() {
    this.toastService.toastsSubject.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((toasts) => {
      this.toasts = toasts;
    });
  }

  removeToast(index: number) {
    this.toastService.remove(index);
  }
}
