<div class="flex flex-col gap-4">
  <div>
    <div class="body-medium mb-1" [innerText]="'Contracts.ExistingVehicles' | translate"></div>
    <div class="flex flex-wrap gap-6">
      @for (licensePlate of userLicensePlates; track $index) {
        <div class="flex items-center gap-2">
          <input type="checkbox" class="switch" (change)="toggleLicensePlate(licensePlate)" [checked]="licensePlate.enabled" />
          <arivo-license-plate
            [country]="licensePlate.license_plate_country"
            [licensePlate]="licensePlate.license_plate"
            [checkInvalid]="true"
          />
        </div>
      }
    </div>
  </div>

  @if (customLicensePlates && customLicensePlates.length > 0) {
    <div>
      <div class="body-medium mb-1" [innerText]="'Contracts.NewVehicles' | translate"></div>
      <div class="flex flex-wrap gap-6">
        @for (licensePlate of customLicensePlates; track $index) {
          <div class="flex items-center gap-2">
            <div class="w-10 text-error text-center">
              <div class="inline-block cursor-pointer p-1" (click)="removeLicensePlate($index)">
                <i class="fa-kit fa-delete"></i>
              </div>
            </div>
            <arivo-license-plate
              [country]="licensePlate.license_plate_country"
              [licensePlate]="licensePlate.license_plate"
              [checkInvalid]="true"
            />
          </div>
        }
      </div>
    </div>
  }
</div>

@if (!showLicensePlateForm) {
  <button class="button-primary-text button-small mt-4" (click)="showLicensePlateForm = true">
    <i class="fa-light fa-plus-large mr-1"></i>
    <span [innerText]="'Common.AddVehicle' | translate"></span>
  </button>
}

@if (showLicensePlateForm) {
  <div class="well mt-4 2xl:max-w-[1000px] lg:max-w-[750px] w-auto ml-auto mr-auto">
    <form [formGroup]="licensePlateForm" (ngSubmit)="addLicensePlate()">
      <div class="xl:flex xl:justify-between">
        <div class="ar-input-group flex-shrink w-full">
          <arivo-license-plate-input class="flex-1" formControlName="licensePlate" [inline]="true" [showErrors]="false" />
          <p class="error" *formError="'licensePlate.required'" [innerText]="'Common.Errors.LicensePlateIsRequired' | translate"></p>
          <p class="error" *formError="'licensePlate.unique'" [innerText]="'Common.VehicleAlreadyExists' | translate"></p>
        </div>
        <div class="max-xl:flex max-xl:flex-wrap max-xl:mt-3 xl:inline-flex items-center justify-end gap-2 ml-5 mt-2">
          <button
            class="button-primary-text button-small mb-auto"
            type="button"
            (click)="showLicensePlateForm = false"
            [innerText]="'Common.Cancel' | translate"
          ></button>
          <button class="button-primary button-small mb-auto" type="submit" [innerText]="'Common.Add' | translate"></button>
        </div>
      </div>
    </form>
  </div>
}
