import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';
import { GarageContract } from '../../../shared/models/contract';

@Directive({
  selector: '[dynamicContractsGrid]',
})
export class DynamicContractsGridDirective implements OnChanges {
  @Input('dynamicContractsGrid') contracts: GarageContract[] = [];

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnChanges() {
    if (this.contracts.length === 1) {
      this.renderer.addClass(this.el.nativeElement, '2xl:grid-cols-2');
      this.renderer.removeClass(this.el.nativeElement, '2xl:grid-cols-3');
    } else if (this.contracts.length > 1) {
      this.renderer.addClass(this.el.nativeElement, '2xl:grid-cols-3');
      this.renderer.removeClass(this.el.nativeElement, '2xl:grid-cols-2');
    }
  }
}
