import { Component } from '@angular/core';
import { Language } from '../../../core/utils/common.data';
import { LanguageService } from '../../../core/services/language.service';

@Component({
  selector: 'app-customer-service-info',
  templateUrl: './customer-service-info.component.html',
  styleUrl: './customer-service-info.component.scss',
})
export class CustomerServiceInfoComponent {
  constructor(private languageService: LanguageService) {}

  getCurrentLanguage(): string {
    return this.languageService.getCurrentLanguage();
  }

  changeLanguage(language: Language): void {
    this.languageService.setLanguage(language);
  }
}
