<form [formGroup]="licensePlateForm">
  <div class="text-center">
    <h2 class="headline-x-small mb-4" [innerText]="'Onboarding.StraightOutOfTheGarage' | translate"></h2>
    <p class="body-medium" [innerText]="'Onboarding.EnterLicensePlateToShowParkingSessions' | translate"></p>
  </div>
  <div class="my-8 ar-input-group">
    <arivo-license-plate-input formControlName="licensePlate" #licensePlateInputComponent (keydown)="enterCheck($event)" />
    <p
      class="error"
      *ngIf="licensePlateForm.controls.licensePlate.touched && licensePlateForm.controls.licensePlate.invalid"
      [innerText]="'Common.Errors.LicensePlateIsRequired' | translate"
    ></p>
  </div>
  <arivo-step-footer [center]="true">
    <button type="button" (click)="next()" class="button-primary" [innerText]="'Common.Continue' | translate"></button>
    <button
      type="button"
      [routerLink]="['/login']"
      class="button-primary-outlined"
      [innerText]="'Onboarding.BackToLogin' | translate"
    ></button>
  </arivo-step-footer>
</form>
