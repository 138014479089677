import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { GarageContract, GarageContractCreate, GarageContractUpdate } from '../../shared/models/contract';
import { Router } from '@angular/router';
import { ContractCreateApiResponse, ContractEditApiResponse } from '../../shared/models/routeTyping';
import { HttpClient } from '@angular/common/http';
import { parseDate, showSpinner } from '../../shared/utils/common.utils';
import { catchError } from 'rxjs/operators';
import { ToastService } from '../../shared/services/toast.service';

@Injectable({
  providedIn: 'root',
})
export class ContractsApiService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private toastService: ToastService,
  ) {}

  getContractCreateApiResponse(): Observable<ContractCreateApiResponse> {
    return this.http.get<ContractCreateApiResponse>(`/api/contract-create`);
  }

  getContractEditApiResponse(id: string): Observable<ContractEditApiResponse | null> {
    return this.http.get<ContractEditApiResponse>(`/api/contract-edit/${id}`, { observe: 'response' }).pipe(
      map((response) => {
        if (response.status === 200) {
          return deserializeContractEditApiResponse(response.body as ContractEditApiResponse);
        }
        throw response;
      }),
      catchError((error) => {
        if (error.status === 404) {
          this.router.navigate(['/dashboard']);
          this.toastService.add({ type: 'error', message: 'Contract not found' });
          return of(null);
        }
        throw error;
      }),
    );
  }

  createContract(contract: GarageContractCreate): Observable<GarageContract> {
    return this.http.post<GarageContract>(`/api/contract/`, serializeContractCreate(contract)).pipe(
      showSpinner(),
      map((x) => deserializeCreateContractResponse(x)),
    );
  }

  updateContract(contract: GarageContractUpdate, id: GarageContract['id']): Observable<GarageContract> {
    return this.http.put<GarageContract>(`/api/contract/${id}`, contract).pipe(
      showSpinner(),
      map((x) => deserializeCreateContractResponse(x)),
    );
  }
}

function serializeContractCreate(contract: GarageContractCreate): any {
  let serializedContract: any = {
    ...contract,
    start: contract.start.toISOString(),
  };
  if (contract.end) {
    serializedContract = {
      ...serializedContract,
      end: contract.end.toISOString(),
    };
  }
  return serializedContract;
}

function deserializeCreateContractResponse(response: any): GarageContract {
  return {
    ...response,
    start: parseDate(response.start),
    end: response.end ? parseDate(response.end) : null,
  };
}

function deserializeContractEditApiResponse(response: ContractEditApiResponse): ContractEditApiResponse {
  response.contract = deserializeCreateContractResponse(response.contract);
  return response;
}
