import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[licensePlateDirective]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ArivoLicensePlateInputDirective),
      multi: true,
    },
  ],
})
export class ArivoLicensePlateInputDirective implements ControlValueAccessor {
  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
    private renderer: Renderer2,
  ) {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'spellcheck', 'true');
    this.renderer.setAttribute(this.elementRef.nativeElement, 'type', 'text');
    this.renderer.addClass(this.elementRef.nativeElement, 'input-primary');
  }

  private onChange = (value: string) => {};
  public onTouched = () => {};

  writeValue(value: string): void {
    this.elementRef.nativeElement.value = this.transformValue(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    const startPosition = inputElement.selectionStart ?? 0;
    const endPosition = inputElement.selectionEnd ?? 0;

    let transformedValue = this.transformValue(value);
    this.onChange(transformedValue);

    // Update the value and restore the cursor position
    this.elementRef.nativeElement.value = transformedValue;
    this.elementRef.nativeElement.setSelectionRange(startPosition, endPosition);
  }

  private transformValue(value: string): string {
    return value.toUpperCase().replace(/[^A-Z0-9ÄÖÜ]/g, '');
  }
}
