import { Component, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { FormBuilder, Validators } from '@angular/forms';
import { ContractLicensePlate, LicensePlate } from '../../../shared/models/licensePlate';
import { GarageContract, GarageContractUpdate } from '../../../shared/models/contract';
import { fbOptionalString } from '../../../shared/utils/form.utils';
import { ArivoBottomSheetDialogComponent } from '../../../shared/components/ui-components/arivo-bottom-sheet-dialog/arivo-bottom-sheet-dialog.component';
import { ContractsApiService } from '../contracts-api.service';
import { ContractEditResolve } from '../../../shared/models/routeTyping';
import { ArivoDialogComponent } from '../../../shared/components/ui-components/arivo-dialog/arivo-dialog.component';
import { Toast, ToastService } from '../../../shared/services/toast.service';
import { DatePipe, Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { RouterService } from '../../../shared/services/router.service';

@Component({
  selector: 'app-contract-edit',
  templateUrl: './contract-edit.component.html',
  styleUrl: './contract-edit.component.scss',
})
export class ContractEditComponent {
  constructor(
    private router: Router,
    private _datePipe: DatePipe,
    private _formBuilder: FormBuilder,
    private toastService: ToastService,
    private routerService: RouterService,
    private translateService: TranslateService,
    private contractsApiService: ContractsApiService,
    @Inject(ActivatedRoute) private route: TypedRoute<ContractEditResolve>,
  ) {
    this.route.data.subscribe((data) => {
      this.contract = data.contractEditApiResponse.contract;
      this.userLicensePlates = data.contractEditApiResponse.license_plates.map((licensePlate: LicensePlate) => {
        if (
          this.contract.license_plates.some(
            (contractLicensePlate) =>
              contractLicensePlate.license_plate === licensePlate.license_plate &&
              contractLicensePlate.license_plate_country === licensePlate.license_plate_country,
          )
        ) {
          return {
            ...licensePlate,
            enabled: true,
          };
        } else {
          return {
            ...licensePlate,
            enabled: false,
          };
        }
      });
    });
  }

  @ViewChild('qrDialog') qrDialog!: ArivoDialogComponent;
  @ViewChild('terminateContractDialog') terminateContractDialog!: ArivoBottomSheetDialogComponent;

  contract!: GarageContract;
  userLicensePlates: ContractLicensePlate[] = [];
  showMoreParkingSpacesThanLicensePlatesWarning: boolean = false;

  terminateContractForm = this._formBuilder.nonNullable.group({
    endDate: [fbOptionalString, [Validators.required]],
  });
  contractForm = this._formBuilder.nonNullable.group({
    licensePlates: [<Array<LicensePlate>>[]],
  });

  updateLicensePlates(licensePlates: LicensePlate[]): void {
    this.contractForm.controls.licensePlates.patchValue(licensePlates);
    this.contractForm.controls.licensePlates.updateValueAndValidity();
  }

  back(): void {
    this.router.navigateByUrl(this.routerService.previousUrl ?? '/dashboard');
  }

  finish(): void {
    this.contractForm.markAllAsTouched();

    const licensePlates = this.contractForm.value.licensePlates;
    const vehicleQuota = this.contract.group_limit;
    if (licensePlates && vehicleQuota && licensePlates.length < vehicleQuota && !this.showMoreParkingSpacesThanLicensePlatesWarning) {
      this.showMoreParkingSpacesThanLicensePlatesWarning = true;
      return;
    }

    if (this.contractForm.valid) {
      this.contract.license_plates = this.contractForm.controls.licensePlates.value;
      this.contract.start = new Date(this.contract.start);
      if (this.contract.end) {
        this.contract.end = new Date(this.contract.end);
      }
      this.updateContract({
        message: this.translateService.instant('Contracts.UpdateContractSuccess', {
          name: this.contract.name,
        }),
        type: 'success',
      });
    }
  }

  endDatepickerFilter = (d: Date | null): boolean => {
    const date = d || new Date();

    // Contract can only be cancelled after it has started and cannot be cancelled in the past
    if (this.contract.start && new Date().setHours(0, 0, 0, 0) < new Date(this.contract.start).setHours(0, 0, 0, 0)) {
      return new Date(this.contract.start).setHours(0, 0, 0, 0) < date.valueOf();
    }
    return new Date().setHours(0, 0, 0, 0) < date.valueOf();
  };

  terminateContract(): void {
    this.terminateContractForm.markAllAsTouched();
    if (this.terminateContractForm.valid) {
      this.contract.start = new Date(this.contract.start);
      this.contract.end = new Date(Date.parse(this.terminateContractForm.controls.endDate.value!));
      this.updateContract({
        message: this.translateService.instant('Contracts.TerminateContractSuccess', {
          name: this.contract.name,
          endDate: this._datePipe.transform(this.terminateContractForm.controls.endDate.value),
        }),
        type: 'success',
      });
      this.terminateContractDialog.open = false;
    }
  }

  updateContract(toast: Toast): void {
    let updatePayload: GarageContractUpdate = {
      license_plates: this.contract.license_plates,
    };
    this.contractsApiService.updateContract(updatePayload, this.contract.id).subscribe({
      next: (data: GarageContract) => {
        this.router.navigateByUrl('/dashboard').finally(() => {
          this.toastService.add(toast);
        });
      },
      error: (error) => {
        console.error(error);
        this.toastService.add({
          message: this.translateService.instant('Contracts.UpdateContractError'),
          type: 'error',
        });
      },
    });
  }
}
