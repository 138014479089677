import { Component, Input } from '@angular/core';

@Component({
  selector: 'arivo-step-footer',
  templateUrl: './arivo-step-footer.component.html',
  styleUrl: './arivo-step-footer.component.scss',
})
export class ArivoStepFooterComponent {
  // Reverse the order of buttons on desktop devices.
  // Default is `true`, as secondary buttons should be displayed below the primary button on mobile devices and left to the primary button on desktop devices by default.
  @Input() reverse: boolean = true;

  // Align the buttons to the center.
  // Default is `false`, as the buttons should be aligned to the right by default.
  @Input() center: boolean = false;
}
