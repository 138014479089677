import { Injectable } from '@angular/core';
import { LicensePlate } from '../../../shared/models/licensePlate';
import { SignupParkingSession } from '../../../shared/models/parkingSession';
import { BehaviorSubject, Observable, tap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(private http: HttpClient) {}

  licensePlate: BehaviorSubject<LicensePlate | null> = new BehaviorSubject<LicensePlate | null>(null);
  onboardingSession: BehaviorSubject<SignupParkingSession | null> = new BehaviorSubject<SignupParkingSession | null>(null);

  getOnboardingSession(licensePlate: LicensePlate): Observable<SignupParkingSession> {
    return this.http
      .get<SignupParkingSession>(
        `/api/onegate/v2/oss/signup_search/?license_plate=${licensePlate.license_plate}&license_plate_country=${licensePlate.license_plate_country}`,
      )
      .pipe(
        tap((res) => {
          this.licensePlate.next(licensePlate);
          this.onboardingSession.next(res);
        }),
        catchError((error) => {
          console.error('An error occurred:', error);
          return throwError(() => error);
        }),
      );
  }

  sendOnboardingEmail(licensePlate: LicensePlate, email: string, marketingConsent?: boolean): Observable<any> {
    return this.http.post(`/api/user/v2/auth/register/email/`, {
      email: email,
      license_plate: licensePlate.license_plate,
      license_plate_country: licensePlate.license_plate_country,
      marketing_consent: marketingConsent,
    });
  }
}
