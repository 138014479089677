import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, Inject, inject } from '@angular/core';
import { BillingInvoice, BillingInvoiceStatus } from '../../../shared/models/invoice';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { BillingResolve } from '../../../shared/models/routeTyping';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { breakPoints } from '../../../core/utils/common.data';
import { Money } from '../../../shared/models/common';

interface InvoicePeriod {
  status: BillingInvoiceStatus | null;
  costs: Money;
  invoices: BillingInvoice[];
  expanded: boolean;
}

@Component({
  selector: 'app-billing-invoices',
  templateUrl: './billing-invoices.component.html',
  styleUrl: './billing-invoices.component.scss',
})
export class BillingInvoicesComponent implements AfterViewInit {
  private _destroyRef = inject(DestroyRef);

  startOpen: boolean = false;

  invoicePeriods: string[] = [];
  invoices: { [key: string]: InvoicePeriod } = {};

  constructor(
    @Inject(ActivatedRoute) private route: TypedRoute<BillingResolve>,
    private cdr: ChangeDetectorRef,
  ) {
    this.route.data.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((data) => {
      const invoices = data.billingApiResponse.invoices;

      let startDate: Date | undefined;
      let endDate: Date | undefined;

      // Group invoices by period
      invoices.forEach((invoice) => {
        const date = new Date(invoice.period_start);
        const key = this.getFormattedPeriod(date);

        if (!startDate || date < startDate) {
          startDate = date;
        }
        if (!endDate || date > endDate) {
          endDate = date;
        }

        if (key in this.invoices) {
          if (this.invoices[key].status !== 'open' && invoice.status === 'open') {
            this.invoices[key].status = 'open';
          }
          this.invoices[key].costs.amount += invoice.costs.amount;
          this.invoices[key].invoices.push(invoice);
        } else {
          this.invoices[key] = {
            status: invoice.status === 'open' ? 'open' : 'paid',
            costs: {
              amount: invoice.costs.amount,
              currency: invoice.costs.currency,
            },
            invoices: [invoice],
            expanded: false,
          };
        }
      });

      // Create a list of months between the earliest and the latest invoice period
      if (startDate && endDate) {
        for (let date = endDate; date >= startDate; date.setMonth(date.getMonth() - 1)) {
          const key = this.getFormattedPeriod(date);
          if (!(key in this.invoices)) {
            this.invoices[key] = {
              status: null,
              costs: {
                amount: 0,
                currency: invoices[0].costs.currency,
              },
              invoices: [],
              expanded: false,
            };
          }
          this.invoicePeriods.push(key);
        }
      }
    });
  }

  collapse(start: number, end: number) {
    for (let i = start; i < end; i++) {
      this.invoices[this.invoicePeriods[i]].expanded = false;
    }
  }

  getFormattedPeriod(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const formattedMonth = month >= 10 ? month : `0${month}`;
    return `${year}-${formattedMonth}`;
  }

  ngAfterViewInit() {
    this.startOpen = window.innerWidth > breakPoints.md;
    this.cdr.detectChanges();
  }
}
