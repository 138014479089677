import { Component, Input } from '@angular/core';

@Component({
  selector: 'arivo-dialog',
  templateUrl: './arivo-dialog.component.html',
  styleUrl: './arivo-dialog.component.scss',
})
export class ArivoDialogComponent {
  @Input() open: boolean = false;
  @Input() dismissible: boolean = true;

  backdropClick(event: Event): void {
    if (event.target === event.currentTarget && this.dismissible) {
      this.open = false;
    }
  }
}
