<arivo-alerts></arivo-alerts>

<div class="headline-small mb-2 md:mb-4" [innerText]="'Vehicles.VehiclesContracts' | translate"></div>

@if (vehicles.length > 0) {
  <!-- Mobile -->
  <div class="flex lg:hidden flex-col gap-2">
    @for (vehicle of vehicles; track vehicle) {
      <arivo-accordion class="border-gray-200 border-1">
        <arivo-accordion-header [caretLeft]="true">
          <div class="flex items-center justify-between gap-4">
            <arivo-license-plate [country]="vehicle.license_plate_country" [licensePlate]="vehicle.license_plate" />
            @if (vehicle.garage_contracts.length > 0) {
              <div
                class="cursor-pointer text-neutral-50"
                (click)="$event.stopPropagation()"
                [matTooltip]="'Vehicles.RemoveContractsBeforeDeletingVehicle' | translate"
              >
                <i class="text-lg fa-kit fa-delete"></i>
              </div>
            } @else {
              <div class="cursor-pointer text-error" (click)="openDeleteVehicleDialog($event, vehicle)">
                <i class="text-lg fa-kit fa-delete"></i>
              </div>
            }
          </div>
        </arivo-accordion-header>
        <arivo-accordion-body>
          @if (vehicle.enabled || vehicle.garage_contracts.length > 0) {
            <div class="flex items-center justify-between">
              <div class="body-medium">
                <span class="font-medium text-decoration-dark" [innerText]="'Vehicles.ShortTermParking' | translate"></span>
              </div>
              <div class="body-medium">
                @if (vehicle.enabled) {
                  <span class="text-decoration-dark" [innerText]="'Common.Allowed' | translate"></span>
                } @else {
                  <span class="text-decoration-dark" [innerText]="'Common.Not allowed' | translate"></span>
                }
              </div>
            </div>
            <hr class="my-4" />
            <div class="body-medium mb-2">
              <span class="font-medium text-decoration-dark" [innerText]="'Vehicles.Contracts' | translate"></span>
            </div>

            @if (vehicle.garage_contracts.length > 0) {
              <div class="flex flex-col gap-2">
                @for (contract of vehicle.garage_contracts; track contract) {
                  <div class="cursor-pointer bg-surface-70 rounded" [routerLink]="['/contracts/edit', contract.id]">
                    <div class="flex items-center gap-4 px-4 py-2">
                      <div class="text-3xl text-success-dark">
                        <i class="fa-kit fa-parking"></i>
                      </div>
                      <div class="flex-grow">
                        <div class="body-large" [innerText]="contract.garage.name"></div>
                        <div class="body-small">
                          <span class="text-neutral-50">
                            <span [innerText]="contract.name"></span>
                            <span>, </span>
                            <span [innerText]="'Contracts.ParkingSpaces' | translatePlural: { count: contract.group_limit }"></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            } @else {
              <div class="body-medium">
                <span class="text-neutral-50" [innerText]="'Vehicles.NoContracts' | translate"></span>
              </div>
            }
          } @else {
            <div class="bg-error-transparent-8 flex items-center gap-4 rounded p-4">
              <div class="flex-shrink flex">
                <i class="text-error fa-light fa-triangle-exclamation"></i>
              </div>
              <div class="flex-1 body-medium" [innerText]="'Vehicles.VehicleCannotBeUsed' | translate"></div>
            </div>
          }
        </arivo-accordion-body>
      </arivo-accordion>
    }
  </div>

  <!-- Desktop -->
  <div class="card hidden lg:block">
    <div class="card-body">
      <table class="table-primary">
        <thead>
          <tr>
            <th class="body-medium">
              <span class="font-medium" [innerText]="'Vehicles.Vehicle' | translate"></span>
            </th>
            <th class="body-medium">
              <span class="font-medium" [innerText]="'Vehicles.ShortTermParking' | translate"></span>
            </th>
            <th class="body-medium">
              <span class="font-medium" [innerText]="'Vehicles.Contracts' | translate"></span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          @for (vehicle of vehicles; track vehicle) {
            <tr>
              <td>
                <arivo-license-plate [country]="vehicle.license_plate_country" [licensePlate]="vehicle.license_plate" />
              </td>
              @if (vehicle.enabled || vehicle.garage_contracts.length > 0) {
                <td>
                  <div class="body-medium">
                    @if (vehicle.enabled) {
                      <span class="text-decoration-dark" [innerText]="'Common.Allowed' | translate"></span>
                    } @else {
                      <span class="text-decoration-dark" [innerText]="'Common.Not allowed' | translate"></span>
                    }
                  </div>
                </td>
                <td>
                  @if (vehicle.garage_contracts.length > 0) {
                    <div class="inline-flex flex-col gap-2">
                      @for (contract of vehicle.garage_contracts; track contract) {
                        <div
                          class="flex items-center gap-2 cursor-pointer bg-surface-70 rounded px-4 py-2"
                          [routerLink]="['/contracts/edit', contract.id]"
                        >
                          <div class="text-3xl text-success-dark">
                            <i class="fa-kit fa-parking"></i>
                          </div>
                          <div class="flex-grow">
                            <div class="body-large" [innerText]="contract.garage.name"></div>
                            <div class="body-small">
                              <span class="text-neutral-50">
                                <span [innerText]="contract.name"></span>
                                <span>, </span>
                                <span [innerText]="'Contracts.ParkingSpaces' | translatePlural: { count: contract.group_limit }"></span>
                              </span>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  } @else {
                    <div class="body-medium">
                      <span class="text-neutral-50" [innerText]="'Vehicles.NoContracts' | translate"></span>
                    </div>
                  }
                </td>
              } @else {
                <td colspan="2">
                  <div class="flex items-center gap-2">
                    <div class="flex-shrink flex">
                      <i class="text-error fa-light fa-triangle-exclamation"></i>
                    </div>
                    <div class="flex-1 body-medium">
                      <span class="text-error" [innerText]="'Vehicles.VehicleCannotBeUsed' | translate"></span>
                    </div>
                  </div>
                </td>
              }
              <td class="w-4">
                @if (vehicle.garage_contracts.length > 0) {
                  <div
                    class="cursor-pointer text-neutral-50"
                    (click)="$event.stopPropagation()"
                    [matTooltip]="'Vehicles.RemoveContractsBeforeDeletingVehicle' | translate"
                  >
                    <i class="text-lg fa-kit fa-delete"></i>
                  </div>
                } @else {
                  <div class="cursor-pointer text-error" (click)="openDeleteVehicleDialog($event, vehicle)">
                    <i class="text-lg fa-kit fa-delete"></i>
                  </div>
                }
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>

  <arivo-dialog #deleteVehicleDialog>
    <div class="card card-small p-2">
      <div class="card-body">
        <div class="body-medium mb-4" [innerHtml]="'Vehicles.DeleteVehicle' | translate"></div>
        <div class="flex items-center justify-end gap-2">
          <button
            class="button-primary-text button-small"
            (click)="closeDeleteVehicleDialog()"
            [innerText]="'Common.Cancel' | translate"
          ></button>
          <button class="button-danger button-small" (click)="deleteVehicle()" [innerText]="'Common.Delete' | translate"></button>
        </div>
      </div>
    </div>
  </arivo-dialog>
} @else {
  <div class="text-center my-6">
    <div class="text-neutral-50 text-2xl">
      <i class="fa-kit fa-vehicles"></i>
    </div>
    <div class="body-medium mt-2">
      <span class="text-neutral-50" [innerText]="'Vehicles.NoVehicles' | translate"></span>
    </div>
  </div>
}
