<arivo-alerts></arivo-alerts>

<div class="flex gap-4 flex-col xl:flex-row mb-8">
  <div class="flex-1">
    <app-dashboard-account-balance />
  </div>
  @if (openParkingSessions.length !== 0) {
    <div class="flex-1">
      <app-dashboard-open-parking-sessions />
    </div>
  }
</div>

<div class="mb-8">
  <app-dashboard-pay-per-use />
</div>

<div>
  <app-dashboard-contracts></app-dashboard-contracts>
</div>
