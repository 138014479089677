@if (!showOnlyDemoLogin) {
  <div class="py-8">
    @if (!emailSent) {
      <div class="card-responsive card-horizontal mx-auto">
        <div class="card-body">
          <div class="mb-5">
            <h3 class="headline-x-small text-decoration-dark text-center" [innerText]="'Common.LogIn' | translate"></h3>
          </div>
          <form [formGroup]="logInForm" (ngSubmit)="sendLogInEmail()">
            <div class="ar-input-group text-left mb-4">
              <div class="input-primary-container">
                <input id="email" type="email" class="input-primary" addEmptyPlaceholder formControlName="email" />
                <label for="email" class="label-primary" [innerText]="'LogIn.EmailAddress' | translate">Email</label>
                <p class="error mt-1" *formError="'email.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
                <p class="error mt-1" *formError="'email.email'" [innerText]="'Common.Errors.ValidEmailIsRequired' | translate"></p>
                <p class="error mt-1" *formError="'email.notFound'" [innerText]="'Your email was not found.' | translate"></p>
                <p class="error mt-1" *formError="'email.notVerified'" [innerText]="'Your email was not verified.' | translate"></p>
                <p class="error mt-1" *formError="'email.emailBanned'" [innerText]="'Your email has been banned.' | translate"></p>
                <p
                  class="error mt-1"
                  *formError="'email.tooManyRequests'"
                  [innerText]="'You\'ve sent too many requests, please try again later' | translate"
                ></p>
                <p
                  class="error mt-1"
                  *formError="'email.unknownError'"
                  [innerText]="'An unknown error has occured, please try again later' | translate"
                ></p>
              </div>
            </div>
            <button class="button-primary w-full" type="submit" [innerText]="'LogIn.SendLoginLink' | translate"></button>
            <button
              type="button"
              class="button-primary-outlined w-full mt-2"
              (click)="logIn()"
              [innerText]="'Demo Login' | translate"
            ></button>
          </form>

          <div class="hidden">
            <div class="separator my-4">or</div>
            <button class="button-primary-outlined w-full gap-2 my-3">
              <i class="fa-brands fa-google"></i>
              <span>Sign in with Google</span>
            </button>
            <button class="button-primary-outlined w-full gap-2 my-3">
              <i class="fa-brands fa-apple"></i>
              <span>Sign in with Apple</span>
            </button>
          </div>
          <a
            routerLink="/onboarding"
            class="body-medium text-interaction-dark block text-center mt-4"
            [innerText]="'LogIn.DoNotHaveAnAccountYet' | translate"
          >
            I don't have an account yet
          </a>
        </div>
        <div class="card-body text-left">
          <app-customer-service-info />
        </div>
      </div>
    } @else {
      <app-login-email [email]="email" (cancelEvent)="emailSent = false" />
    }
  </div>
} @else {
  <div class="py-8">
    <div class="card-responsive card-horizontal mx-auto">
      <div class="card-body">
        <div class="mb-5">
          <h3 class="headline-x-small text-decoration-dark text-center" [innerText]="'Common.LogIn' | translate"></h3>
        </div>
        <div class="mb-10">
          <h3 class="body-large text-decoration-dark text-center" [innerText]="'Temporary.DemoEnvironment' | translate"></h3>
        </div>
        <button type="button" class="button-primary-outlined w-full mt-2" (click)="logIn()" [innerText]="'Demo Login' | translate"></button>
      </div>
      <div class="card-body text-left">
        <app-customer-service-info />
      </div>
    </div>
  </div>
}
