import { DestroyRef, inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CoreDataService } from '../../core/services/core-data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export type Alert = {
  title?: string;
  message: string;
  type: 'info' | 'warning' | 'error' | 'success';
  dismissible: boolean;
  resolveButton?: { text: string; link: string[] };
};

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  alertsSubject = new BehaviorSubject<Alert[]>([]);
  private _destroyRef = inject(DestroyRef);

  paymentMethodAlert: Alert = {
    message: 'Alert.AddPaymentMethodDescription',
    type: 'error',
    dismissible: false,
  };

  constructor(private coreDataService: CoreDataService) {
    this.coreDataService.coreData.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((coreData) => {
      if (!coreData?.payment_method_available) {
        this.add(this.paymentMethodAlert);
      } else {
        this.removeAlert(this.paymentMethodAlert);
      }
    });
  }

  add(alert: Alert) {
    const alertExists = this.alertsSubject.value.some(
      (existingAlert) => existingAlert.message === alert.message && existingAlert.type === alert.type,
    );

    if (!alertExists) {
      this.alertsSubject.next([...this.alertsSubject.value, alert]);
    }
  }

  removeAlert(alert: Alert) {
    this.alertsSubject.next(this.alertsSubject.value.filter((a) => a !== alert));
  }

  removeAll() {
    this.alertsSubject.next([]);
  }
}
