<div class="card">
  <div class="card-header">
    <div class="headline-large">Allgemeine Geschäftsbedingungen (AGBs)</div>
  </div>
  <div class="card-body">
    <div class="headline-small">Allgemeinen Geschäftsbedingungen für Parkgaragen, Parkhäuser und Parkplätze</div>
    <div class="headline-x-small">Version 2 (gültig ab 19.10.2023)</div>
  </div>
  <div class="card-body">
    <div class="body-medium">
      <ol>
        <li class="font-bold text-lg">1. Allgemeine Bestimmungen</li>
        <li>
          1.1. Die Benützung der Garagen- bzw. Einstell-/Abstellflächen („Garagen“) ist nur nach Abschluss eines Nutzungsvertrages zulässig.
          Der Parkplatznutzer („Kunde“) schließt einen Nutzungsvertrag mit dem Garagenbetreiber ab. Bei Kurzparkern kommt ein kurzfristiger
          Nutzungsvertrag durch die Erfassung einer Einfahrt (wie z.B. einfache Einfahrt, Nutzung einer Kreditkarte oder Verwendung eines
          berechtigten Mediums als registrierter Kunde, wie z.B. Kennzeichen, Transponder, ...), bei Dauerparkern durch den Abschluss eines
          schriftlichen Nutzungsvertrages (Dauerparkvertrag) zustande.
        </li>
        <li>1.2. Die Arivo GmbH fungiert lediglich als Auftragsverarbeiter gemäß Art 4 Z 8 DSGVO auf und ist nicht Garagenbetreiber.</li>
        <li>1.3. Der Nutzungsvertrag fällt nicht in den Anwendungsbereich des Mietrechtsgesetzes (MRG).</li>
        <li>
          1.4 Jeder Kunde unterwirft sich mit Abschluss des Nutzungsvertrages diesen Bedingungen. Bei Ablehnung der in dieser Garagenordnung
          enthaltenen Bedingungen ist die freie Ausfahrt möglich, wenn sie unverzüglich nach der Einfahrt erfolgt.
        </li>
      </ol>
      <ol>
        <li class="font-bold text-lg">2. Tarife, Entgelte, Abrechnung</li>
        <li>
          2.1. Die jeweils gültigen Tarife, sonstigen Entgelte und die Betriebszeiten sind dem Aushang zu entnehmen. 2.2. Einfahrt, Ausfahrt
          sowie Zutritt sind grundsätzlich nur innerhalb der Betriebszeiten mittels Einfahrtsberechtigung möglich. 2.3. Die Abrechnung der
          Online-Zahlungen erfolgt mittels des Zahlungsanbieters Stripe. Der Kunde kann aus unterschiedlichen Zahlungsmethoden wählen (Apple
          Pay, Google Pay, Debit/Kreditkarte, SEPA-Lastschrift, …). Die Abrechnung der Parkgebühren für Kurzparker erfolgt nach beendetem
          Parkvorgang. Die Abrechnung für Dauerparker oder registrierte Kurzparker erfolgt monatlich im Nachhinein. Die Software von Arivo
          erstellt monatlich eine Rechnung der Parkgebühren und diese werden durch den Zahlungsdienstleister Stripe eingezogen.
        </li>
      </ol>
      <ol>
        <li class="font-bold text-lg">3. Vertragsgegenstand</li>
        <li>
          3.1. Mit Abschluss des Nutzungsvertrages erhält der Kunde die Berechtigung, ein betriebs- und verkehrssicheres Fahrzeug auf einem
          markierten, freien und geeigneten Einstellplatz abzustellen; im Falle eines Bestehens von Beschränkungen (z.B. Reservierungen,
          beschränkte Abstelldauer) sind diese immer strikt zu beachten. Gekennzeichnete Behindertenabstellplätze dürfen ausschließlich von
          Behinderten mit gültigem, gut sichtbarem Parkausweis für Behinderte gemäß § 29b StVO bzw. Behindertenpass mit Eintrag
          „Unzumutbarkeit der Benützung öffentlicher Verkehrsmittel“ benützt werden.
        </li>
        <li>
          3.2. Am Betriebsstandort gilt sinngemäß die länderspezifische Straßenverkehrsordnung (StVO) in der jeweils gültigen Fassung.
          Außerdem ist die vorgeschriebene Geschwindigkeitsbeschränkung einzuhalten.
        </li>
        <li>
          3.3. Die Bewachung und Verwahrung des Fahrzeuges, seines Zubehörs sowie allfälliger im Fahrzeug befindlicher Gegenstände oder mit
          dem Fahrzeug in die Garage eingebrachter Sachen ist nicht Vertragsgegenstand.
        </li>
      </ol>
      <ol>
        <li class="font-bold text-lg">4. Haftung</li>
        <li>
          4.1. Der Garagenbetreiber haftet keinesfalls für das Verhalten Dritter (z.B. Diebstahl, Einbruch, Beschädigung) unabhängig davon,
          ob sich diese Dritten befugt oder unbefugt im Betriebsstandort aufhalten.
        </li>
        <li>4.2. Der Betreiber haftet weiters nicht für Schäden, die mittelbar oder unmittelbar durch höhere Gewalt entstehen.</li>
        <li>
          4.3. Der Kunde ist verpflichtet, das abgestellte Fahrzeug ordnungsgemäß zu sichern und abzuschließen sowie unverzüglich den
          Betriebsstandort zu verlassen.
        </li>
        <li>4.4. Den Anordnungen des Garagenpersonals ist im Interesse eines reibungslosen Betriebes Folge zu leisten.</li>
        <li>
          4.5. Allfällige Beschädigungen von Garageneinrichtungen oder an anderen Fahrzeugen durch den Kunden sind unverzüglich und vor der
          Ausfahrt dem Garagenbetreiber zu melden; ebenso festgestellte Schäden am eigenen Fahrzeug. Allfällige gesetzliche Meldepflichten
          bleiben davon unberührt.
        </li>
      </ol>
      <ol>
        <li class="font-bold text-lg">5. Ordnungsvorschriften</li>
        <li>
          5.1. Fahrzeuge, die in den Betriebsstandort eingebracht werden, müssen verkehrs- und betriebssicher und zum Verkehr zugelassen
          sein.
        </li>
        <li>5.2. Verboten sind insbesondere:</li>
        <li>5.2.1. das Rauchen sowie die Verwendung von Feuer und offenem Licht;</li>
        <li>5.2.2. das Abstellen und die Lagerung von Gegenständen aller Art, insbesondere von brennbaren und explosiven Stoffen;</li>
        <li>
          5.2.3. Wartungs-, Pflege- und Reparaturarbeiten wie insbesondere das Betanken von Fahrzeugen, Aufladung von Starterbatterien sowie
          das Ablassen des Kühlwassers;
        </li>
        <li>5.2.4. das längere Laufen lassen und das Ausprobieren des Motors und das Hupen;</li>
        <li>
          5.2.5. die Einstellung eines Fahrzeuges mit undichtem Betriebssystem (insbesondere bei Austritt von Treibstoff, Öl oder sonstige
          Flüssigkeiten) oder anderen, insbesondere sicherheitsrelevanten Mängeln, sowie die Einstellung solcher Fahrzeuge, die den
          verkehrstechnischen Vorschriften nicht entsprechen (z.B. ungültige oder abgelaufene Überprüfungsplakette);
        </li>
        <li>
          5.2.6. ohne Zustimmung vom Garagenbetreiber das Abstellen von Fahrzeugen ohne polizeiliches Kennzeichen oder ohne Anbringung eines
          Ersatzkennzeichens;
        </li>
        <li>
          5.2.7. das Abstellen des Fahrzeuges auf den Fahrstreifen, vor Notausgängen, auf Fußgängerwegen, vor Türen (Toren) und Ausgängen,
          im Bewegungsbereich von Türen und Toren;
        </li>
        <li>5.2.8. das Verteilen von Werbematerial ohne schriftliche Zustimmung des Garagenbetreibers;</li>
        <li>5.2.9. das Befahren der Garage mit Fahrrad, Skateboard, Roller, Inlineskates und dgl.</li>
      </ol>
      <ol>
        <li class="font-bold text-lg">6. Zurückbehaltungsrecht</li>
        <li>
          6.1. Zur Sicherung der Entgeltforderungen sowie aller im Zusammenhang mit der Garagierung gegenüber dem Kunden entstehenden
          Forderungen steht dem Betreiber ein Zurückbehaltungsrecht am eingebrachten Fahrzeug zu, selbst dann, wenn das Fahrzeug nicht dem
          Kunden, sondern einem Dritten gehört.
        </li>
        <li>
          6.2. Zur Sicherung des Zurückbehaltungsrechtes kann der Betreiber durch geeignete Mittel die Entfernung des Fahrzeuges verhindern.
          Die Ausübung des Zurückbehaltungsrechtes kann durch eine Sicherheitsleistung abgewendet werden.
        </li>
      </ol>
      <ol>
        <li class="font-bold text-lg">7. Verhalten im Brandfall</li>
        <li>
          7.1. Bei Brand oder Brandgeruch ist die Feuerwehr zu verständigen und allenfalls vorhandene Alarmierungseinrichtungen auszulösen.
          Die Meldung hat folgende Angaben zu enthalten: <br />WO brennt es (Adresse, Zufahrtswege), WAS brennt (Gebäude, Auto), WIE viele
          Verletzte gibt es, WER ruft an (Name). Allfällig angebrachte Hinweisschilder „Verhalten im Brandfall“ sind zu beachten.
        </li>
        <li>7.2. Sofern notwendig und möglich, sind gefährdete Personen zu warnen und Verletzte bzw. hilflose Personen zu evakuieren.</li>
        <li>
          7.3. Soweit es unter Beachtung der eigenen Sicherheit möglich ist, ist ein Löschversuch mit einem geeigneten Feuerlöscher zu
          unternehmen, andernfalls ist die Garage auf schnellstem Wege zu Fuß zu verlassen.
        </li>
        <li>7.4. Aufzüge im Brandfall nicht benützen!</li>
      </ol>
      <ol>
        <li class="font-bold text-lg">8. Bildaufzeichnungen</li>
        <li>8.1. Der Betreiber setzt Bildaufzeichnungen für folgende Zwecke ein:</li>
        <li>8.1.1. Verwendung des KFZ-Kennzeichens als Parkberechtigungsmedium bei der Ein- und Ausfahrt (visuell bzw. automatisiert).</li>
        <li>8.1.2. Zum Schutz der betriebenen Garage bzw. zur Einhaltung von Sorgfaltspflichten.</li>
        <li>8.2. Die Einhaltung der gesetzlichen Bestimmungen (DSGVO, DSG) wird gewährleistet.</li>
        <li>
          8.3. Die Bildaufzeichnungen dienen insbesondere nicht der Bewachung des Fahrzeuges und begründen keine Haftung des
          Garagenbetreibers.
        </li>
      </ol>
      <ol>
        <li class="font-bold text-lg">9. Datenschutz</li>
        <li>
          9.1. Der Betreiber verarbeitet zum Zwecke der Vertragserfüllung die dafür erforderlichen personenbezogenen Daten der Kunden.
          Nähere Informationen zur Datenverarbeitung enthält die Datenschutzerklärung gemäß DSGVO, welche im Zuge des Abschlusses eines
          Nutzungsvertrages zur Verfügung gestellt wird.
        </li>
      </ol>
    </div>
  </div>
</div>
