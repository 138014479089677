import { ApplicationRef, Injectable } from '@angular/core';
import { ArivoDialogComponent } from '../components/ui-components/arivo-dialog/arivo-dialog.component';
import { Platform } from '@angular/cdk/platform';
import { ToastService } from './toast.service';
import { isStandalone } from '../../core/utils/platform.utils';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  constructor(
    private platform: Platform,
    private toastService: ToastService,
    private applicationRef: ApplicationRef,
    private translateService: TranslateService,
  ) {}

  // Using `any` because `BeforeInstallPromptEvent` is not standard yet
  public homeScreenDeferredPrompt?: any;

  public get isAppInstalled(): boolean {
    return isStandalone();
  }

  installApp(continueInTheAppDialog: ArivoDialogComponent, instructionsDialog: ArivoDialogComponent) {
    if (this.homeScreenDeferredPrompt) {
      this.homeScreenDeferredPrompt.prompt();
      this.homeScreenDeferredPrompt.userChoice
        .then((userChoice: any) => {
          if (userChoice.outcome === 'accepted' && this.platform.ANDROID) {
            continueInTheAppDialog.open = true;
          }
          this.homeScreenDeferredPrompt = null;
          this.applicationRef.tick();
        })
        .catch(() => {
          this.toastService.add({
            title: this.translateService.instant('AppCouldNotBeInstalled'),
            message: this.translateService.instant('AppCouldNotBeInstalledDescription'),
            type: 'error',
          });
          this.homeScreenDeferredPrompt = null;
          this.applicationRef.tick();
        });
    } else if (!isStandalone()) {
      instructionsDialog.open = true;
    }
  }
}
