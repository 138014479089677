import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { AuthService } from '../../../core/auth/auth.service';
import { showSpinner } from '../../../shared/utils/common.utils';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrl: './onboarding.component.scss',
})
export class OnboardingComponent {
  constructor(
    @Inject(ActivatedRoute) private route: TypedRoute<{ token: string }>,
    private authService: AuthService,
  ) {
    this.route.data.subscribe((data) => {
      if (data.token) {
        this.authService
          .verifySignupJWT(data.token)
          .pipe(showSpinner())
          .subscribe({
            next: (data) => {
              if (data.parking_session) {
                const licensePlate = data.parking_session.license_plate.license_plate;
                const licensePlateCountry = data.parking_session.license_plate.license_plate_country;

                this.authService.logIn(
                  data.key,
                  `/onboarding/finish?license_plate=${licensePlate}&license_plate_country=${licensePlateCountry}`,
                );
              } else {
                this.authService.logIn(data.key, '/onboarding/finish');
              }
            },
            error: (error: HttpErrorResponse) => {
              this.authService.showTokenVerifyErrorToast(error);
            },
          });
      }
    });
  }
}
