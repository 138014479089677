import { Component, Inject } from '@angular/core';
import { AuthService } from '../../core/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { fbString } from '../../shared/utils/form.utils';
import { LoginService } from './login.service';
import { TypedRoute } from '../../shared/utils/router.utils';
import { ToastService } from '../../shared/services/toast.service';
import { showSpinner } from '../../shared/utils/common.utils';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  constructor(
    @Inject(ActivatedRoute) private route: TypedRoute<{ token: string }>,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {
    this.route.data.subscribe((data) => {
      if (data.token) {
        this.authService
          .verifyLoginJWT(data.token)
          .pipe(showSpinner())
          .subscribe({
            next: (data) => {
              this.authService.logIn(data.key);
            },
            error: (error: HttpErrorResponse) => {
              this.authService.showTokenVerifyErrorToast(error);
            },
          });
      }
    });
  }

  logInForm = this.formBuilder.nonNullable.group({
    email: [fbString, [Validators.required, Validators.email]],
  });

  emailSent: boolean = false;
  email?: string;
  showOnlyDemoLogin: boolean = environment.demoLoginOnly;

  sendLogInEmail() {
    this.logInForm.markAllAsTouched();
    if (this.logInForm.valid) {
      this.email = this.logInForm.value.email!;
      this.loginService
        .sendLoginEmail(this.email)
        .pipe(showSpinner())
        .subscribe({
          next: () => {
            this.emailSent = true;
            this.toastService.add({
              message: this.translateService.instant('LogIn.WeHaveSentAnEmailToast', { email: this.email }),
              type: 'success',
            });
          },
          error: (err) => {
            if (err.status === 404) {
              if (err.error.error_code === 'not_found') {
                this.logInForm.controls.email.setErrors({ notFound: true });
              }
            } else if (err.status === 400) {
              if (err.error.detail?.email) {
                this.logInForm.controls.email.setErrors({ notVerified: true });
              }
              if (err.error.detail?.nonFieldErrors) {
                this.logInForm.controls.email.setErrors({ emailBanned: true });
              }
            } else if (err.status === 429) {
              this.logInForm.controls.email.setErrors({ tooManyRequests: true });
            } else {
              this.logInForm.controls.email.setErrors({ unknownError: true });
            }
          },
        });
    }
  }

  logIn(): void {
    this.authService.logIn('demotoken');
  }
}
