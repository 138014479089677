@if (steps) {
  <div class="stepper">
    @if (showHeader) {
      <div class="stepper-header">
        @for (step of steps; track $index) {
          <div class="stepper-header-item" [class.active]="step.state === 'active'" [class.done]="step.state === 'done'">
            @if (step.state !== 'done') {
              <div class="stepper-header-item-number" [innerText]="$index + 1"></div>
            } @else {
              <div class="stepper-header-item-number"><i class="fa-solid fa-check"></i></div>
            }
            <div class="stepper-header-item-label" [innerText]="step.label"></div>
          </div>
        }
      </div>
    }
    <div class="stepper-body">
      <ng-content></ng-content>
    </div>
  </div>
}
