import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'arivo-bottom-sheet-dialog',
  templateUrl: './arivo-bottom-sheet-dialog.component.html',
  styleUrl: './arivo-bottom-sheet-dialog.component.scss',
})
export class ArivoBottomSheetDialogComponent {
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() onOpen: EventEmitter<void> = new EventEmitter<void>();

  get open(): boolean {
    return this._open;
  }
  @Input() set open(value: boolean) {
    this._open = value;
    if (!value) {
      this.onClose.emit();
    } else {
      this.onOpen.emit();
    }
  }
  private _open: boolean = false;

  backdropClick(event: Event): void {
    if (event.target === event.currentTarget) {
      this.open = false;
    }
  }
}
