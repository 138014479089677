import { FormBuilder, Validators } from '@angular/forms';
import { fbOptionalString } from '../../../../shared/utils/form.utils';
import { ArivoStepperComponent } from '../../../../shared/components/ui-components/arivo-stepper/arivo-stepper.component';
import { Garage } from '../../../../shared/models/garage';
import { ActivatedRoute, Router } from '@angular/router';
import { TypedRoute } from '../../../../shared/utils/router.utils';
import { ContractCreateResolve } from '../../../../shared/models/routeTyping';
import { ContractCreateService } from '../contract-create.service';
import { Component, DestroyRef, inject, Inject, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterService } from '../../../../shared/services/router.service';

@Component({
  selector: 'app-contract-create-first-step',
  templateUrl: './contract-create-first-step.component.html',
  styleUrl: './contract-create-first-step.component.scss',
})
export class ContractCreateFirstStepComponent implements OnInit {
  @Input() stepperComponent!: ArivoStepperComponent;
  @Input() garagesObservable!: Observable<Garage[]>;

  private destroyRef = inject(DestroyRef);

  constructor(
    private router: Router,
    private _formBuilder: FormBuilder,
    private routerService: RouterService,
    private contractCreateService: ContractCreateService,
    @Inject(ActivatedRoute) private route: TypedRoute<ContractCreateResolve>,
  ) {}

  ngOnInit() {
    this.garagesObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((garages) => {
      this.garages = garages;

      const garageId = this.route.snapshot.queryParams['garageId'];
      const productId = this.route.snapshot.queryParams['productId'];
      if (garageId && productId) {
        this.contractForm.patchValue({ garage: garageId });
        this.updateProductSelection(garageId);
        this.contractForm.patchValue({ product: productId });
      } else if (!this.contractForm.value.garage && this.garages.length > 0) {
        this.contractForm.patchValue({ garage: this.garages[0].id });
        this.updateProductSelection(this.garages[0].id);
      }
    });

    // Subscribe to changes in the garage selection
    this.contractForm
      .get('garage')
      ?.valueChanges.pipe()
      .subscribe((id) => {
        this.updateProductSelection(id);
      });
  }

  garages: Garage[] = [];

  get garage(): Garage | undefined {
    return this.garages.find((garage) => garage.id === this.contractForm.value.garage);
  }

  contractForm = this._formBuilder.nonNullable.group({
    garage: [fbOptionalString, Validators.required],
    product: [fbOptionalString, Validators.required],
  });

  updateProductSelection(selectedGarageId: Garage['id'] | null): void {
    const selectedGarage = this.garages.find((garage) => garage.id === selectedGarageId);
    if (selectedGarage && selectedGarage.products.length > 0) {
      this.contractForm.patchValue({ product: selectedGarage.products[0].id });
    }
  }

  back(): void {
    this.router.navigateByUrl(this.routerService.previousUrl ?? '/dashboard');
  }

  continue(): void {
    this.contractForm.markAllAsTouched();
    if (this.contractForm.valid) {
      const garage = this.garages.find((garage) => garage.id === this.contractForm.value.garage);
      const product = garage?.products.find((product) => product.id === this.contractForm.value.product);

      if (garage && product) {
        this.contractCreateService.garageSubject.next(garage);
        this.contractCreateService.productSubject.next(product);
      }

      this.stepperComponent.next();
    }
  }
}
