import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StorageManagerService {
  constructor() {}

  public store(key: string, value: any, storage: Storage = localStorage): boolean {
    try {
      storage.setItem(key, JSON.stringify(value));
      return true;
    } catch {
      return false;
    }
  }

  public load<T = any>(key: string, storage: Storage = localStorage): T | undefined {
    try {
      const data: string | null = storage.getItem(key);
      return data ? JSON.parse(data) : undefined;
    } catch {
      return undefined;
    }
  }

  public delete(key: string, storage: Storage = localStorage): boolean {
    try {
      storage.removeItem(key);
      return true;
    } catch {
      return false;
    }
  }
}
