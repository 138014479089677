import { Component, DestroyRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { LicensePlate, PayPerUseLicensePlate } from '../../../shared/models/licensePlate';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { requireLicensePlate } from '../../../shared/utils/form.utils';
import { ArivoLicensePlateInputComponent } from '../../../shared/components/ui-components/arivo-license-plate-input/arivo-license-plate-input.component';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { DashboardResolve } from '../../../shared/models/routeTyping';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DashboardApiService } from '../dashboard-api.service';
import { formatLicensePlate } from '../../../shared/utils/license-plate.utils';
import { showSpinner } from '../../../shared/utils/common.utils';
import { ToastService } from '../../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-pay-per-use',
  templateUrl: './dashboard-pay-per-use.component.html',
  styleUrl: './dashboard-pay-per-use.component.scss',
})
export class DashboardPayPerUseComponent implements OnInit {
  @ViewChild(ArivoLicensePlateInputComponent) licensePlateInputComponent!: ArivoLicensePlateInputComponent;
  private _destroyRef = inject(DestroyRef);

  get showLicensePlateForm(): boolean {
    return this._showLicensePlateForm;
  }
  set showLicensePlateForm(value: boolean) {
    this._showLicensePlateForm = value;
    this.licensePlateForm.reset();
  }
  private _showLicensePlateForm: boolean = false;

  licensePlates: PayPerUseLicensePlate[] = [];

  garageAmount: number = 0;

  constructor(
    private _formBuilder: FormBuilder,
    @Inject(ActivatedRoute) private route: TypedRoute<DashboardResolve>,
    private dashboardApiService: DashboardApiService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {
    this.route.data.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((data) => {
      this.licensePlates = data.dashboardApiResponse.pay_per_use_license_plates;
      this.garageAmount = data.dashboardApiResponse.pay_per_use_garage_count;
    });
  }

  get enabledLicensePlates(): number {
    return this.licensePlates.filter((licensePlate) => licensePlate.enabled).length;
  }

  onPayPerUseCheckboxClicked(licensePlate: PayPerUseLicensePlate): void {
    this.dashboardApiService
      .togglePayPerUseLicensePlate({ ...licensePlate, enabled: !licensePlate.enabled })
      .pipe(showSpinner())
      .subscribe({
        next: (updatedPlate) => {
          this.licensePlates = this.licensePlates.map((plate) => {
            if (plate.license_plate === licensePlate.license_plate && plate.license_plate_country === licensePlate.license_plate_country) {
              return updatedPlate;
            }
            return plate;
          });
        },
        error: (error) => {
          if (error.status === 400 && error.error.error_code == 'validation_error' && error.error.detail?.non_field_errors?.length !== 0) {
            for (const errorMessage of error.error.detail.non_field_errors) {
              this.toastService.add({ message: errorMessage, type: 'error' });
            }
          } else {
            this.toastService.add({ message: 'Errors.UnknownError', type: 'error' });
          }
        },
      });
  }

  licensePlateForm = this._formBuilder.nonNullable.group({
    licensePlate: [<LicensePlate>{}, [requireLicensePlate, this.uniqueLicensePlate.bind(this)]],
  });

  uniqueLicensePlate(control: AbstractControl) {
    if (
      this.licensePlates.some((licensePlate) => {
        return (
          licensePlate.license_plate_country === control.value.license_plate_country &&
          licensePlate.license_plate === control.value.license_plate
        );
      })
    ) {
      return { unique: true };
    }
    return null;
  }

  addLicensePlate(): void {
    this.licensePlateInputComponent.markAllAsTouched();
    if (this.licensePlateForm.valid) {
      this.dashboardApiService
        .addPayPerUseLicensePlate(this.licensePlateForm.value.licensePlate!)
        .pipe(showSpinner())
        .subscribe({
          next: (newPayPerUsePlate: PayPerUseLicensePlate) => {
            this.licensePlates.push(newPayPerUsePlate);
            this.showLicensePlateForm = false;
            this.licensePlateForm.reset();
          },
          error: (error) => {
            if (error.error.detail?.non_field_errors) {
              this.toastService.add({ message: error.error.detail.non_field_errors[0], type: 'error' });
            } else {
              this.toastService.add({ message: this.translateService.instant('Common.Errors.UnknownError'), type: 'error' });
            }
          },
        });
    }
  }

  ngOnInit(): void {
    this.licensePlateForm.controls.licensePlate.addValidators(this.uniqueLicensePlate.bind(this));
  }

  protected readonly formatLicensePlate = formatLicensePlate;
}
