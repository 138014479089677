import { Component, Inject, Input } from '@angular/core';
import { ArivoStepperComponent } from '../../../../shared/components/ui-components/arivo-stepper/arivo-stepper.component';
import { FormBuilder, Validators } from '@angular/forms';
import { ContractLicensePlate, LicensePlate } from '../../../../shared/models/licensePlate';
import { fbString } from '../../../../shared/utils/form.utils';
import { ContractCreateService } from '../contract-create.service';
import { Garage } from '../../../../shared/models/garage';
import { ContractTemplate } from '../../../../shared/models/contractTemplate';
import { ActivatedRoute, Router } from '@angular/router';
import { TypedRoute } from '../../../../shared/utils/router.utils';
import { ContractCreateResolve } from '../../../../shared/models/routeTyping';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../../shared/services/toast.service';
import { ContractsApiService } from '../../contracts-api.service';
import { pairwise } from 'rxjs';
import { GarageContract } from '../../../../shared/models/contract';

@Component({
  selector: 'app-contract-create-second-step',
  templateUrl: './contract-create-second-step.component.html',
  styleUrl: './contract-create-second-step.component.scss',
})
export class ContractCreateSecondStepComponent {
  @Input() stepperComponent!: ArivoStepperComponent;

  constructor(
    private router: Router,
    private _formBuilder: FormBuilder,
    private toastService: ToastService,
    private translateService: TranslateService,
    private contractsApiService: ContractsApiService,
    private contractCreateService: ContractCreateService,
    @Inject(ActivatedRoute) private route: TypedRoute<ContractCreateResolve>,
  ) {
    const licensePlates: LicensePlate[] = this.route.snapshot.data.contractCreateApiResponse.license_plates;
    this.licensePlates = licensePlates.map((licensePlate: LicensePlate) => {
      return {
        ...licensePlate,
        enabled: false,
      };
    });

    this.contractCreateService.garageSubject.subscribe((garage) => {
      if (this.garage !== garage) {
        this.garage = garage;

        this.licensePlates = licensePlates.map((licensePlate: LicensePlate) => {
          return {
            ...licensePlate,
            enabled: false,
          };
        });

        this.contractForm.reset();
        this.contractForm.controls.licensePlates.reset();
        this.contractForm.controls.start.patchValue(new Date().toISOString().split('T')[0]);
        this.contractForm.controls.parkingSpots.patchValue(1);
        this.contractForm.controls.licensePlates.patchValue([]);
      }
    });

    this.contractCreateService.productSubject.subscribe((product) => {
      this.product = product;
    });

    this.contractForm.valueChanges.pipe(pairwise()).subscribe(([oldVal, newVal]) => {
      if (oldVal.parkingSpots !== newVal.parkingSpots) {
        this.contractForm.controls.licensePlates.updateValueAndValidity();
      }
    });
  }

  garage?: Garage;
  product?: ContractTemplate;
  licensePlates: ContractLicensePlate[] = [];
  showMoreParkingSpacesThanLicensePlatesWarning: boolean = false;

  contractForm = this._formBuilder.group({
    start: [fbString, Validators.required],
    parkingSpots: [1, Validators.required],
    licensePlates: [<Array<LicensePlate>>[]],
    termsAndConditions: [false, [Validators.requiredTrue]],
  });

  startDatepickerFilter = (d: Date | null): boolean => {
    const date = d || new Date();
    return new Date().setHours(0, 0, 0, 0) <= date.valueOf();
  };

  get totalPrice(): number {
    const price = this.product?.price.amount;
    const vehicleQuota = this.contractForm.value.parkingSpots;
    if (this.contractForm.controls.parkingSpots.valid && price && vehicleQuota) {
      return price * vehicleQuota;
    }
    return 0;
  }

  updateLicensePlates(licensePlates: LicensePlate[]) {
    this.contractForm.controls.licensePlates.patchValue(licensePlates);
    this.contractForm.controls.licensePlates.updateValueAndValidity();
  }

  finish() {
    this.contractForm.markAllAsTouched();
    this.contractForm.controls.licensePlates.updateValueAndValidity();

    const licensePlates = this.contractForm.value.licensePlates;
    const vehicleQuota = this.contractForm.value.parkingSpots;
    if (licensePlates && vehicleQuota && licensePlates.length < vehicleQuota && !this.showMoreParkingSpacesThanLicensePlatesWarning) {
      this.showMoreParkingSpacesThanLicensePlatesWarning = true;
      return;
    }

    if (this.contractForm.valid && this.garage && this.product) {
      const contract = {
        template_id: this.product.id,
        group_limit: this.contractForm.value.parkingSpots!,
        license_plates: licensePlates!,
        start: new Date(this.contractForm.value.start!),
        end: null,
      };

      this.contractsApiService.createContract(contract).subscribe({
        next: (data: GarageContract) => {
          this.router.navigateByUrl('/dashboard').finally(() => {
            this.toastService.add({
              message: this.translateService.instant('Contracts.CreateContractSuccess'),
              type: 'success',
            });
          });
        },
        error: (error) => {
          this.toastService.add({
            message: this.translateService.instant('Contracts.CreateContractError'),
            type: 'error',
          });
        },
      });
    }
  }
}
