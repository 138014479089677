import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Vehicle } from '../../shared/models/licensePlate';

@Injectable({
  providedIn: 'root',
})
export class VehiclesApiService {
  constructor(private http: HttpClient) {}

  getVehiclesResponse(): Observable<Vehicle[]> {
    return this.http.get<Vehicle[]>(`/api/backoffice/v2/customer/license_plate/`);
  }

  deleteVehicle(vehicleId: string): Observable<null> {
    return this.http.delete<null>(`/api/backoffice/v2/customer/license_plate/${vehicleId}/`);
  }
}
