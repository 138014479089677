import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { StorageManagerService } from '../services/storage-manager.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private storageManagerService: StorageManagerService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Exclude requests to local assets
    if (request.url.startsWith('assets')) {
      return next.handle(request);
    }

    let headers: { [key: string]: string } = {};

    const token = this.storageManagerService.load('token');
    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }
    headers['Accept-Language'] = this.storageManagerService.load('language') ?? 'en';

    request = request.clone({
      setHeaders: headers,
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 500) {
          this.router.navigate(['/error']);
        }
        return throwError(() => error);
      }),
    );
  }
}
