<div class="tabs">
  <ul class="tab-group">
    <button class="select-none" *ngFor="let tab of tabs" (click)="selectTab(tab)" [class.active]="tab.active">
      <div *ngIf="tab.icon" class="text-xl text-decoration-dark">
        <i [class]="tab.icon"></i>
      </div>
      <span class="body-medium text-decoration-dark">{{ tab.title }}</span>
    </button>
  </ul>
  <div class="card">
    <div class="card-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
