import { Component, DestroyRef, inject, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { ContractCreateResolve } from '../../../shared/models/routeTyping';
import { map, Observable, switchMap, tap } from 'rxjs';
import { Garage } from '../../../shared/models/garage';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-contract-create',
  templateUrl: './contract-create.component.html',
  styleUrl: './contract-create.component.scss',
})
export class ContractCreateComponent {
  garagesObservable: Observable<Garage[]>;
  bookableContractsAvailable: boolean = false;

  private destroyRef = inject(DestroyRef);

  constructor(
    private _location: Location,
    @Inject(ActivatedRoute) private route: TypedRoute<ContractCreateResolve>,
  ) {
    this.garagesObservable = this.route.data.pipe(
      map((data) => {
        return data.contractCreateApiResponse.garages.filter((garage) => garage.products.length > 0);
      }),
    );

    this.garagesObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((garages) => {
      this.bookableContractsAvailable = garages.length > 0;
    });
  }

  back(): void {
    this._location.back();
  }
}
