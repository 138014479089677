import { Component, DestroyRef, inject } from '@angular/core';
import { ArivoDialogService, Dialog } from './arivo-dialog.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

@Component({
  selector: 'arivo-dialog-container',
  templateUrl: './arivo-dialog-container.component.html',
  styleUrl: './arivo-dialog-container.component.scss',
})
export class ArivoDialogContainerComponent {
  destroyRef = inject(DestroyRef);
  dialogues: Dialog[] = [];

  constructor(
    private dialogService: ArivoDialogService,
    private router: Router,
  ) {
    this.dialogService.dialogues.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((dialogues) => {
      this.dialogues = dialogues;
    });
  }

  handleButtonClick(index: number): void {
    const dialog = this.dialogues[index];
    if (dialog.buttonRoute) {
      this.router.navigate([dialog.buttonRoute.link], { queryParams: dialog.buttonRoute.queryParams });
    }
    this.dialogService.removeDialog(index);
  }

  handleDismiss(index: number): void {
    this.dialogService.removeDialog(index);
  }

  protected readonly inject = inject;
}
