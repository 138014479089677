import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { BillingParkingSession } from '../../../shared/models/parkingSession';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { BillingResolve } from '../../../shared/models/routeTyping';
import { breakPoints } from '../../../core/utils/common.data';

@Component({
  selector: 'app-billing-park-sessions',
  templateUrl: './billing-parking-sessions.component.html',
  styleUrl: './billing-parking-sessions.component.scss',
})
export class BillingParkingSessionsComponent implements OnInit, AfterViewInit {
  _destroyRef = inject(DestroyRef);
  parkingSessions: BillingParkingSession[] = [];
  startOpen: boolean = false;

  constructor(
    @Inject(ActivatedRoute) private route: TypedRoute<BillingResolve>,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.route.data.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((data) => {
      this.parkingSessions = [...data.billingApiResponse.unpaid_parking_sessions].sort((a, b) => {
        return new Date(b.exit_date_time).getTime() - new Date(a.exit_date_time).getTime();
      });
    });
  }

  ngAfterViewInit() {
    this.startOpen = window.innerWidth > breakPoints.md;
    this.cdr.detectChanges();
  }
}
