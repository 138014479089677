import { Component, DestroyRef, inject } from '@angular/core';
import { Alert, AlertService } from '../../../services/alert.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'arivo-alerts',
  templateUrl: './arivo-alerts.component.html',
  styleUrl: './arivo-alerts.component.scss',
})
export class ArivoAlertsComponent {
  private _destroyRef = inject(DestroyRef);
  alerts: Alert[] = [];

  constructor(private alertService: AlertService) {
    this.alertService.alertsSubject.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((alerts: Alert[]) => {
      this.alerts = alerts;
    });
  }

  closeAlert(alert: Alert) {
    this.alertService.removeAlert(alert);
  }
}
