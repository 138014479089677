<div class="license-plate license-plate-compact" [class.license-plate-eu]="fullCountry.euCountry" [class.license-plate-compact]="compact">
  @if (fullCountry.lprCode !== 'UNKNOWN') {
    <div class="license-plate-country">
      <div class="license-plate-country-flag">
        @if (fullCountry.euCountry) {
          <span class="fi fi-eu"></span>
        } @else {
          <span [ngClass]="'fi fi-' + fullCountry.iso2Code.toLowerCase()"></span>
        }
      </div>
      <div class="license-plate-country-name" [innerText]="country"></div>
    </div>
  }
  <div class="license-plate-number" [ngClass]="invalidWarning ? 'invalid' : ''" [innerText]="licensePlate?.toUpperCase()"></div>
  @if (invalidWarning) {
    <i class="warning-icon fa-light fa-triangle-exclamation pr-2" [matTooltip]="'Tooltip.LicensePlateMaybeInvalid' | translate"></i>
  }
</div>
