<div class="nav" [class.open]="navigationOpen" [class.nav-transition]="!windowResizing">
  <div class="nav-header">
    <div class="nav-close" (click)="closeNavigation()"><i class="fa-light fa-xmark"></i></div>
    <img [src]="'/assets/brands/wipark.png' | url" alt="Arivo" />
  </div>
  <nav class="nav-body">
    <div class="nav-items">
      <a class="nav-item" routerLinkActive="active" [routerLink]="['/dashboard']">
        <div class="nav-icon">
          <i class="nav-icon fa-kit fa-dashboard"></i>
        </div>
        <div class="nav-item-label" [innerText]="'Sidebar.Overview' | translate"></div>
      </a>
      <a class="nav-item" routerLinkActive="active" [routerLink]="['/products']">
        <div class="nav-icon">
          <i class="fa-kit fa-contract"></i>
        </div>
        <div class="nav-item-label" [innerText]="'Sidebar.CarParksTariffs' | translate"></div>
      </a>
      <a class="nav-item" routerLinkActive="active" [routerLink]="['/vehicles']">
        <div class="nav-icon">
          <i class="fa-kit fa-vehicles"></i>
        </div>
        <div class="nav-item-label" [innerText]="'Sidebar.VehiclesAndContracts' | translate"></div>
      </a>
      <a class="nav-item" routerLinkActive="active" [routerLink]="['/billing']">
        <div class="nav-icon">
          <i class="fa-kit fa-wallet"></i>
        </div>
        <div class="nav-item-label" [innerText]="'Sidebar.Billing' | translate"></div>
      </a>
      <a class="nav-item" routerLinkActive="active" [routerLink]="['/data']">
        <div class="nav-icon">
          <i class="fa-kit fa-data"></i>
        </div>
        <div class="nav-item-label" [innerText]="'Sidebar.ProfileSettings' | translate"></div>
      </a>
      <a class="nav-item" routerLinkActive="active" [routerLink]="['/help']">
        <div class="nav-icon">
          <i class="fa-kit fa-info"></i>
        </div>
        <div class="nav-item-label" [innerText]="'Sidebar.HelpContact' | translate"></div>
      </a>
      <a class="nav-item" routerLinkActive="active" [routerLink]="['/access-card']">
        <div class="nav-icon">
          <i class="fa-kit fa-qr-code"></i>
        </div>
        <span class="nav-item-label" [innerText]="'Sidebar.AccessCard' | translate"></span>
      </a>
      <a class="nav-item cursor-pointer" (click)="onLogOutClicked()">
        <div class="nav-icon">
          <i class="fa-kit fa-logout"></i>
        </div>
        <div class="nav-item-label" [innerText]="'Sidebar.LogOut' | translate"></div>
      </a>
    </div>
  </nav>
  <div class="nav-footer">
    <div class="nav-footer-languages">
      <a (click)="changeLanguage('de')" [class.active]="getCurrentLanguage() === 'de'">Deutsch</a> |
      <a (click)="changeLanguage('en')" [class.active]="getCurrentLanguage() === 'en'">English</a>
    </div>
    <div class="nav-footer-branding">
      <img [src]="'/assets/brands/arivo.svg' | url" alt="Arivo" />
    </div>
    <div class="nav-footer-links">
      <a [routerLink]="['/data-privacy']" [title]="'Sidebar.DataPrivacy' | translate" [innerText]="'Sidebar.DataPrivacy' | translate"></a>
      <a
        [routerLink]="['/terms-and-conditions']"
        [title]="'Sidebar.TermsAndConditions' | translate"
        [innerText]="'Sidebar.TermsAndConditions' | translate"
      ></a>
      <a
        href="https://www.wipark.at/unternehmen/impressum"
        target="_blank"
        [title]="'Sidebar.Imprint' | translate"
        [innerText]="'Sidebar.Imprint' | translate"
      ></a>
    </div>
  </div>
</div>
<div class="nav-backdrop" (click)="closeNavigation()"></div>
