import { Component, DestroyRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { GarageContract } from '../../../shared/models/contract';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { DashboardResolve } from '../../../shared/models/routeTyping';
import { ArivoDialogComponent } from '../../../shared/components/ui-components/arivo-dialog/arivo-dialog.component';
import { HttpClient } from '@angular/common/http';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-dashboard-contracts',
  templateUrl: './dashboard-contracts.component.html',
  styleUrl: './dashboard-contracts.component.scss',
})
export class DashboardContractsComponent implements OnInit {
  @ViewChild('qrDialog') qrDialog!: ArivoDialogComponent;
  private _destroyRef = inject(DestroyRef);
  qrCode: null | string = null;
  hasPaymentMethod = false;

  contracts: GarageContract[] = [];

  constructor(
    @Inject(ActivatedRoute) private route: TypedRoute<DashboardResolve>,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((data) => {
      this.contracts = data.dashboardApiResponse.garage_contracts;
      this.hasPaymentMethod = !!data.dashboardApiResponse.payment_method;
    });
  }

  openQrDialog(id: GarageContract['id']) {
    this.qrCode = null;
    this.qrDialog.open = true;
    this.http
      .get('https://meyer2.digimon.arivo.fun/api/appearance/qr/png/MGBDQ3VZU2c4SDNrVGllWHhDUUVxeWlncmdhTVhC/', { responseType: 'blob' })
      .subscribe((imageBlob: Blob) => {
        this.qrCode = URL.createObjectURL(imageBlob);
      });
  }
}
