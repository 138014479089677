<div class="bottom-sheet-dialog" [class.open]="open" (click)="backdropClick($event)">
  <div class="bottom-sheet-dialog-content">
    <div class="hidden">
      <div class="flex justify-end">
        <div class="bottom-sheet-dialog-close" (click)="open = false">
          <i class="fa-solid fa-xmark"></i>
        </div>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
