import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export type AssetHashes = {
  [filePath: string]: string;
};

@Injectable({
  providedIn: 'root',
})
export class AssetHashesService {
  private _assetHashesBehaviourSubject: BehaviorSubject<AssetHashes | undefined> = new BehaviorSubject<AssetHashes | undefined>(undefined);

  constructor() {}

  public assetHashesChanges(): Observable<AssetHashes | undefined> {
    return this._assetHashesBehaviourSubject.asObservable();
  }

  public setAssetHashes(assetHashes: AssetHashes): void {
    this._assetHashesBehaviourSubject.next(assetHashes);
  }

  public getHashedAssetUrl(fileUrl: string): string {
    const clearedFileUrl: string = this._getClearedFileUrl(fileUrl);
    const assetHashes: AssetHashes | undefined = this._assetHashesBehaviourSubject.getValue();
    const assetHashFileUrl: string | undefined = assetHashes?.[clearedFileUrl];
    return (assetHashFileUrl ?? clearedFileUrl).replace(/^\//, '');
  }

  // Returns the file URL that starts with a slash `/`.
  private _getClearedFileUrl(fileUrl: string): string {
    fileUrl = fileUrl.replace('./', '/');

    if (!fileUrl.startsWith('/')) {
      fileUrl = `/${fileUrl}`;
    }

    return fileUrl;
  }

  getAssetHashesUrl(): string {
    return 'assetHashes.json';
  }
}
