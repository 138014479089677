import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { BillingApiResponse } from '../../shared/models/routeTyping';
import { parseDate } from '../../shared/utils/common.utils';

@Injectable({
  providedIn: 'root',
})
export class BillingApiService {
  constructor(private http: HttpClient) {}

  getBillingApiResponse(): Observable<BillingApiResponse> {
    return this.http.get<BillingApiResponse>(`/api/billing-data`).pipe(map((x) => deserializeBillingApiResponse(x)));
  }
}

function deserializeBillingApiResponse(response: BillingApiResponse): BillingApiResponse {
  response.invoices = response.invoices.map((invoice) => {
    return {
      ...invoice,
      invoice_date: parseDate(invoice.invoice_date),
      period_start: parseDate(invoice.period_start),
      period_end: parseDate(invoice.period_end),
    };
  });
  response.unpaid_parking_sessions = response.unpaid_parking_sessions.map((parkingSession) => ({
    ...parkingSession,
    entry_date_time: parseDate(parkingSession.entry_date_time),
    exit_date_time: parseDate(parkingSession.exit_date_time),
  }));
  return response;
}
