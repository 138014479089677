import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { delay, Observable, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  addRandomProductsToGarages,
  exampleBillingInvoices,
  exampleGarageContracts,
  exampleGarages,
  exampleLicensePlates,
  exampleOpenCosts,
  examplePaymentMethod,
  examplePayPerUseLicensePlates,
  exampleSignupSearchResponse,
  exampleUser,
  exampleVehicles,
  generateExampleUnpaidParkingSessions,
  generateRandomOpenParkingSessions,
} from '../utils/example.data';
import { LicensePlate, PayPerUseLicensePlate, Vehicle } from '../../shared/models/licensePlate';
import { GarageContract, GarageContractCreate } from '../../shared/models/contract';

type MockApiEndpoint = {
  url: string;
  method: string;
  response: (req?: HttpRequest<any>) => Observable<HttpResponse<any>>;
};

@Injectable()
export class MockHttpInterceptor implements HttpInterceptor {
  constructor(private http: HttpClient) {}

  //prettier-ignore
  private mockApiEndpoints: MockApiEndpoint[] = [
    //AUTH
    { url: '/api/user/v2/auth/login/email/', method: 'POST', response: this.mockLoginEmailResponse.bind(this) },
    { url: '/api/user/v2/auth/login/verify/', method: 'POST', response: this.mockLoginVerify.bind(this) },
    { url: '/api/user/v2/auth/register/email', method: 'POST', response: this.mockRegisterEmailResponse.bind(this) },
    { url: '/api/user/v2/auth/register/verify/', method: 'POST', response: this.mockRegisterVerifyResponse.bind(this) },
    //RESOLVERS
    { url: '/api/access-card/qr-code', method: 'GET', response: this.mockQrCodeResponse.bind(this) },
    { url: '/api/backoffice/v2/customer/license_plate/', method: 'GET', response: this.mockVehiclesResponse.bind(this) },
    { url: '/api/backoffice/v2/customer_portal/dashboard_data/', method: 'GET', response: this.mockDashboardResponse.bind(this),},
    { url: '/api/billing-data', method: 'GET', response: this.mockBillingResponse.bind(this) },
    { url: '/api/contract-create', method: 'GET', response: this.mockContractCreateResponse.bind(this) },
    { url: '/api/contract-edit', method: 'GET', response: this.mockContractEditResponse.bind(this) },
    { url: '/api/core-data', method: 'GET', response: this.mockCoreDataResponse.bind(this) },
    { url: '/api/onboarding', method: 'GET', response: this.mockOnboardingResponse.bind(this) },
    { url: '/api/products', method: 'GET', response: this.mockProductsResponse.bind(this) },
    { url: '/api/user', method: 'GET', response: this.mockUserResponse.bind(this) },
    //ACTIONS
    { url: '/api/backoffice/v2/customer/license_plate/', method: 'DELETE', response: this.mockVehicleDeleteResponse.bind(this) },
    { url: '/api/backoffice/v2/customer_portal/pay_per_use/', method: 'POST', response: this.mockAddPayPerUseResponse.bind(this) },
    { url: '/api/backoffice/v2/customer_portal/pay_per_use/toggle/', method: 'PUT', response: this.mockTogglePayPerUseResponse.bind(this) },
    { url: '/api/contract', method: 'POST', response: this.mockContractPostResponse.bind(this) },
    { url: '/api/contract', method: 'PUT', response: this.mockContractPutResponse.bind(this) },
    { url: '/api/onegate/v2/oss/signup_search', method: 'GET', response: this.mockSignupSearchResponse.bind(this) },
    { url: '/api/user', method: 'PUT', response: this.mockUserPutResponse.bind(this) },
    //ADYEN
    { url: '/api/invoice/v2/payment_method/', method: 'POST', response: this.mockInitiatePaymentSessionResponse.bind(this) },
    { url: '/api/invoice/v2/payment_method/available_payment_methods/', method: 'GET', response: this.mockAvailablePaymentMethodsResponse.bind(this) },
    { url: '/api/invoice/v2/payment_method/submit_authentication_details/', method: 'POST', response: this.mockAdditionalDetailsResponse.bind(this) },
  ];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.mockCalls && req.url.startsWith('/api')) {
      console.info('Mocking API call:', req.method, req.url);
      const endpoint = this.mockApiEndpoints.find((ep) => req.url.startsWith(ep.url) && req.method === ep.method);
      if (endpoint) {
        return endpoint.response(req);
      }
    }
    return next.handle(req);
  }

  mockAvailablePaymentMethodsResponse() {
    return this.handleRealResponse(
      'get',
      environment.name === 'development'
        ? `http://localhost:3000/payment-methods`
        : `https://wipark.digimon.arivo.fun/api/invoice/v2/payment_method/available_payment_methods/`,
    );
  }

  mockInitiatePaymentSessionResponse(req?: HttpRequest<any>) {
    return this.handleRealResponse(
      'post',
      environment.name === 'development'
        ? `http://localhost:3000/payments`
        : `https://wipark.digimon.arivo.fun/api/invoice/v2/payment_method/`,
      req!.body,
    );
  }

  mockAdditionalDetailsResponse(req?: HttpRequest<any>) {
    return this.handleRealResponse(
      'post',
      environment.name === 'development'
        ? `http://localhost:3000/payments/details`
        : `https://wipark.digimon.arivo.fun/api/invoice/v2/payment_method/submit_authentication_details/`,
      req!.body,
    );
  }

  mockValidateToken() {
    return localStorage.getItem('token') === 'invalidtoken' ? this.handleMockError(400) : this.handleMockResponse({}, 250);
  }

  mockRegisterVerifyResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development') {
      return this.handleMockResponse(
        {
          key: 'demotoken',
          transfer_error: false,
          parking_session: {
            amount_unpaid: {
              amount: '3.20',
              currency: 'EUR',
            },
            entry_time: '2024-08-01T11:29:15',
            duration_in_seconds: 2958,
            license_plate: {
              license_plate: 'G:WIPA100',
              license_plate_country: 'D',
            },
            is_cleared: false,
          },
        },
        250,
      );
    }
    return this.handleRealResponse('post', 'https://wipark.digimon.arivo.fun' + req!.url, req!.body);
  }

  mockLoginVerify(req?: HttpRequest<any>) {
    return environment.name === 'development'
      ? this.handleMockResponse({ key: 'demotoken' }, 250)
      : this.handleRealResponse('post', 'https://wipark.digimon.arivo.fun' + req!.url, req!.body);
  }

  mockQrCodeResponse() {
    return this.handleRealResponse(
      'get',
      `https://wipark.digimon.arivo.fun/api/backoffice/v2/customer_portal/door_opener_qr_code/ `,
      undefined,
      { responseType: 'blob' },
    );
  }

  mockBillingResponse() {
    return this.handleMockResponse({
      payment_method: examplePaymentMethod,
      open_costs: exampleOpenCosts,
      unpaid_parking_sessions: generateExampleUnpaidParkingSessions(100),
      invoices: exampleBillingInvoices,
    });
  }

  mockContractCreateResponse() {
    let garages = exampleGarages;
    addRandomProductsToGarages(garages);
    return this.handleMockResponse({
      garages,
      license_plates: exampleLicensePlates,
    });
  }

  private mockContractEditResponse(req?: HttpRequest<any>) {
    const contract = exampleGarageContracts.find((contract) => contract.id === req!.url.split('/')[3]);
    if (!contract) {
      console.warn(`Contract ${req!.url.split('/')[3]} not found!`);
      return this.handleMockError(404);
    }
    return this.handleMockResponse({ contract, license_plates: exampleLicensePlates });
  }

  mockDashboardResponse() {
    if (environment.name === 'development' || localStorage.getItem('token') === '"demotoken"') {
      return this.handleMockResponse({
        payment_method: examplePaymentMethod,
        open_costs: exampleOpenCosts,
        garage_contracts: exampleGarageContracts,
        open_parking_sessions: [...generateRandomOpenParkingSessions(Math.floor(Math.random() * 5))],
        pay_per_use_license_plates: [...examplePayPerUseLicensePlates],
        pay_per_use_garage_count: 5,
      });
    } else {
      return this.handleRealResponse('get', `https://api.digimon.arivo.fun/backoffice/v2/customer_portal/dashboard_data/`);
    }
  }

  mockCoreDataResponse() {
    return this.handleMockResponse({ payment_method_available: true });
  }

  mockOnboardingResponse() {
    return this.handleMockResponse({ user: exampleUser, payment_method: examplePaymentMethod });
  }

  mockUserResponse() {
    return this.handleMockResponse(exampleUser);
  }

  mockSignupSearchResponse(req?: HttpRequest<any>): Observable<HttpResponse<any>> {
    // Extract license plate details from the request URL
    const urlParams = new URLSearchParams(req!.url.split('?')[1]);
    const licensePlate = urlParams.get('license_plate');

    if (environment.name === 'development' || environment.name === 'production') {
      let mockSignupSearchResponse = exampleSignupSearchResponse;

      if (licensePlate?.startsWith('X')) {
        mockSignupSearchResponse = null;
      } else {
        mockSignupSearchResponse = {
          ...mockSignupSearchResponse,
          license_plate: {
            license_plate: licensePlate,
            license_plate_country: urlParams.get('license_plate_country'),
          },
          is_cleared: licensePlate?.startsWith('A'),
        };
      }
      return this.handleMockResponse(mockSignupSearchResponse);
    } else {
      return this.handleRealResponse('get', 'https://wipark.digimon.arivo.fun' + req!.url);
    }
  }

  mockTogglePayPerUseResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development' || localStorage.getItem('token') === '"demotoken"') {
      const { license_plate, license_plate_country } = req!.body;

      const licensePlateIndex = examplePayPerUseLicensePlates.findIndex(
        (lp) => lp.license_plate === license_plate && lp.license_plate_country === license_plate_country,
      );

      if (licensePlateIndex !== -1) {
        examplePayPerUseLicensePlates[licensePlateIndex].enabled = req!.body.enabled;
      }
      return this.handleMockResponse(req!.body);
    } else {
      return this.handleRealResponse('put', 'https://wipark.digimon.arivo.fun' + req!.url, req!.body);
    }
  }

  mockAddPayPerUseResponse(req?: HttpRequest<any>): Observable<any> {
    if (environment.name === 'development' || localStorage.getItem('token') === '"demotoken"') {
      let licensePlate: PayPerUseLicensePlate = {
        ...req!.body,
        enabled: true,
      };
      examplePayPerUseLicensePlates.push(licensePlate);
      exampleLicensePlates.push(req!.body);

      return this.handleMockResponse(licensePlate);
    } else {
      return this.handleRealResponse('post', 'https://wipark.digimon.arivo.fun' + req!.url, req!.body);
    }
  }

  mockContractPostResponse(req?: HttpRequest<any>) {
    let request: GarageContractCreate = req!.body;
    let newContract: GarageContract = {
      ...request,
      id: Math.random().toString(36).substring(7),
      name: 'Beispielvertrag',
      garage: {
        name: 'Beispielgarage',
        street: 'Beispielstraße',
        number: '1',
        zip_code: '12345',
        city: 'Beispielstadt',
      },
      price: {
        amount: 100,
        currency: 'EUR',
      },
      minimum_duration_months: null,
      notice_period_months: 3,
    };
    exampleGarageContracts.push(newContract);
    return this.handleMockResponse(newContract);
  }

  mockContractPutResponse(req?: HttpRequest<any>) {
    const updatedContract = req!.body;
    const contractIndex = exampleGarageContracts.findIndex((contract) => contract.id === req!.url.split('/')[3]);
    if (contractIndex === -1) {
      return of(new HttpResponse({ status: 404, body: 'Contract not found' }));
    }

    updatedContract.license_plates.forEach((updatedLicensePlate: any) => {
      const existingLicensePlate = examplePayPerUseLicensePlates.find(
        (lp) =>
          lp.license_plate === updatedLicensePlate.license_plate && lp.license_plate_country === updatedLicensePlate.license_plate_country,
      );

      if (!existingLicensePlate) {
        examplePayPerUseLicensePlates.push({
          ...updatedLicensePlate,
          enabled: false,
        });
        exampleLicensePlates.push({
          ...updatedLicensePlate,
        });
      }
    });

    exampleGarageContracts[contractIndex] = {
      ...exampleGarageContracts[contractIndex],
      ...updatedContract,
    };

    return this.handleMockResponse(exampleGarageContracts[contractIndex]);
  }

  mockUserPutResponse(req?: HttpRequest<any>) {
    console.log('Mocking user PUT request:', req!.body);
    console.log(exampleUser);
    exampleUser.email = req!.body.email;
    exampleUser.first_name = req!.body.first_name;
    exampleUser.last_name = req!.body.last_name;
    exampleUser.address = req!.body.address;
    exampleUser.zip_code = req!.body.zip_code;
    exampleUser.city = req!.body.city;
    exampleUser.country = req!.body.country;
    exampleUser.business_account = req!.body.business_account;
    exampleUser.company_name = req!.body.company_name;
    exampleUser.uid = req!.body.uid;
    console.log(exampleUser);
    return this.handleMockResponse(exampleUser);
  }

  mockProductsResponse(req?: HttpRequest<any>) {
    let garages = exampleGarages;
    addRandomProductsToGarages(garages);
    return this.handleMockResponse({
      map_options: {
        center: null,
        zoom: 14,
      },
      garages,
    });
  }

  mockVehiclesResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development' || localStorage.getItem('token') === '"demotoken"') {
      const vehicles = examplePayPerUseLicensePlates.map((plate: PayPerUseLicensePlate) => {
        const vehicle: Vehicle = {
          id: Math.random().toString(36).substring(2, 15),
          license_plate_country: plate.license_plate_country,
          license_plate: plate.license_plate,
          enabled: plate.enabled,
          garage_contracts: [],
        };

        exampleGarageContracts.forEach((contract) => {
          if (
            contract.license_plates.some(
              (lp: LicensePlate) => lp.license_plate === plate.license_plate && lp.license_plate_country === plate.license_plate_country,
            )
          ) {
            vehicle.garage_contracts.push({
              id: contract.id,
              name: contract.name,
              group_limit: contract.group_limit,
              garage: {
                name: contract.garage.name,
              },
            });
          }
        });
        return vehicle;
      });
      exampleVehicles.length = 0;
      exampleVehicles.push(...vehicles);

      return this.handleMockResponse(vehicles);
    } else {
      return this.handleRealResponse('get', 'https://wipark.digimon.arivo.fun' + req!.url);
    }
  }

  mockVehicleDeleteResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development' || localStorage.getItem('token') === '"demotoken"') {
      const vehicleId = req!.url.split('/')[6];
      const vehicle = exampleVehicles.find((vehicle) => vehicle.id === vehicleId);
      if (vehicle) {
        const { license_plate, license_plate_country } = vehicle;

        // Remove from examplePayPerUseLicensePlates
        const ppuIndex = examplePayPerUseLicensePlates.findIndex(
          (plate) => plate.license_plate === license_plate && plate.license_plate_country === license_plate_country,
        );
        if (ppuIndex !== -1) {
          examplePayPerUseLicensePlates.splice(ppuIndex, 1);
        }
        const plateIndex = exampleLicensePlates.findIndex(
          (plate) => plate.license_plate === license_plate && plate.license_plate_country === license_plate_country,
        );
        if (plateIndex !== -1) {
          exampleLicensePlates.splice(plateIndex, 1);
        }

        // Remove from exampleVehicles
        const vehicleIndex = exampleVehicles.indexOf(vehicle);
        exampleVehicles.splice(vehicleIndex, 1);
      }

      return this.handleMockResponse(null);
    } else {
      return this.handleRealResponse('delete', 'https://wipark.digimon.arivo.fun' + req!.url);
    }
  }

  mockRegisterEmailResponse(req?: HttpRequest<any>) {
    if (environment.name === 'development') {
      return this.handleMockResponse({});
    } else {
      return this.handleRealResponse('post', 'https://wipark.digimon.arivo.fun' + req!.url, req!.body);
    }
  }

  mockLoginEmailResponse(req?: HttpRequest<any>) {
    return this.mockRegisterEmailResponse(req);
  }

  private handleMockResponse<T>(responseBody: T, delayMs: number = 500): Observable<HttpResponse<T>> {
    return of(new HttpResponse({ status: 200, body: responseBody })).pipe(delay(delayMs));
  }

  private handleMockError<T>(status: number, body?: T) {
    let request$: Observable<any> = this.http.get<T>('https://httpstat.us/' + status);

    return request$.pipe(switchMap((response) => of(new HttpResponse({ status: response.status, body: body ?? response.body }))));
  }

  private handleRealResponse<T>(
    method: 'get' | 'post' | 'put' | 'delete',
    url: string,
    body?: any,
    options?: any,
  ): Observable<HttpResponse<T>> {
    console.info('Mock overwritten by real API call:', url);
    let request$: Observable<any>;

    switch (method) {
      case 'get':
        request$ = this.http.get<T>(url, { observe: 'response', ...options });
        break;
      case 'post':
        request$ = this.http.post<T>(url, body, { observe: 'response', ...options });
        break;
      case 'put':
        request$ = this.http.put<T>(url, body, { observe: 'response', ...options });
        break;
      case 'delete':
        request$ = this.http.delete<T>(url, { observe: 'response', ...options });
        break;
      default:
        throw new Error(`Unsupported HTTP method: ${method}`);
    }

    return request$.pipe(switchMap((response) => of(new HttpResponse({ status: 200, body: response.body }))));
  }
}
