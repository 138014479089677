import { Component, DestroyRef, Inject, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ArivoStepperComponent } from '../../../../shared/components/ui-components/arivo-stepper/arivo-stepper.component';
import { fbBoolean, fbOptionalString, fbString } from '../../../../shared/utils/form.utils';
import { licensePlateCountries } from '../../../../shared/data/countries.data';
import { User } from '../../../../shared/models/user';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../user/user.service';
import { ISO2CountryCode } from '../../../../shared/models/country';
import { ArivoPaymentService } from '../../../../shared/components/arivo-payment-container/arivo-payment.service';
import { showSpinner } from '../../../../shared/utils/common.utils';
import { LanguageService } from '../../../../core/services/language.service';
import { ArivoPaymentMethod } from '../../../../shared/models/common';
import { CoreDataService } from '../../../../core/services/core-data.service';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../../../shared/utils/router.utils';
import { OnboardingFinishResolve } from '../../../../shared/models/routeTyping';

@Component({
  selector: 'app-onboarding-finish-second-step',
  templateUrl: './onboarding-finish-second-step.component.html',
  styleUrl: './onboarding-finish-second-step.component.scss',
})
export class OnboardingFinishSecondStepComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  paymentMethod: ArivoPaymentMethod | null = null;
  creditCardIconClass: string | null = null;
  user: User | null = null;

  //This only reorders the list on reload, if we need to have it instantly we may have to adjust this
  selectableCountries = licensePlateCountries
    .slice()
    .sort((a, b) =>
      this.translateService.instant('CountryNames.' + a.name).localeCompare(this.translateService.instant('CountryNames.' + b.name)),
    );

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private userService: UserService,
    private coreDataService: CoreDataService,
    private languageService: LanguageService,
    private paymentService: ArivoPaymentService,
    @Inject(ActivatedRoute) private route: TypedRoute<OnboardingFinishResolve>,
  ) {}

  @Input({ required: true }) stepperComponent?: ArivoStepperComponent;

  contactDetailsForm = this.initContactDetailsForm();

  ngOnInit() {
    this.initializeUserData();
    this.setupValueChangeHandlers();
  }

  next(): void {
    this.contactDetailsForm.markAllAsTouched();
    if (this.contactDetailsForm.valid) {
      let updatedUser: User = {
        email: this.user!.email,
        first_name: this.contactDetailsForm.value.first_name ?? null,
        last_name: this.contactDetailsForm.value.last_name ?? null,
        business_account: this.isBusinessAccount,
        company_name: this.contactDetailsForm.value.company_name ?? null,
        uid: this.contactDetailsForm.value.uid ?? null,
        address: this.contactDetailsForm.value.address ?? null,
        zip_code: this.contactDetailsForm.value.zip_code ?? null,
        city: this.contactDetailsForm.value.city ?? null,
        country: this.contactDetailsForm.value.country ?? 'AT',
      };

      this.userService
        .updateUser(updatedUser)
        .pipe(showSpinner())
        .subscribe({
          next: (user) => {
            this.userService.setUser(user);
            if (this.stepperComponent) {
              this.stepperComponent.next();
            }
          },
          error: (error) => {
            console.error('Error updating user data:', error);
          },
        });
    }
  }

  // Helper functions
  get isBusinessAccount() {
    return this.contactDetailsForm.value.business_account ?? false;
  }
  private initContactDetailsForm() {
    return this.formBuilder.nonNullable.group({
      first_name: [fbString, Validators.required],
      last_name: [fbString, Validators.required],
      address: [fbString, Validators.required],
      zip_code: [fbString, Validators.required],
      city: [fbString, Validators.required],
      country: [<ISO2CountryCode>'A', Validators.required],
      business_account: [fbBoolean],
      company_name: [fbOptionalString],
      uid: [fbOptionalString],
    });
  }

  private initializeUserData() {
    this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
      this.user = data.onboardingFinishApiResolve.user;
      this.populateForms(this.user);
      this.paymentMethod = data.onboardingFinishApiResolve.payment_method ?? null;
      if (this.paymentMethod) {
        this.creditCardIconClass = this.paymentService.getCreditCardIcon(this.paymentMethod.brand);
      }
    });
    this.paymentService.activePaymentMethod.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (paymentMethod) => {
        if (paymentMethod) {
          this.paymentMethod = paymentMethod;
          this.creditCardIconClass = this.paymentService.getCreditCardIcon(this.paymentMethod.brand);
        }
      },
    });
    this.languageService.languageChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (language) => {
        this.selectableCountries = licensePlateCountries
          .slice()
          .sort((a, b) =>
            this.translateService.instant('CountryNames.' + a.name).localeCompare(this.translateService.instant('CountryNames.' + b.name)),
          );
      },
    });
  }

  private populateForms(user: User | null) {
    if (!user) return;
    this.populateContactDetailsForm(user);
  }

  private populateContactDetailsForm(user: User) {
    this.contactDetailsForm.controls.first_name.patchValue(user?.first_name ?? fbString);
    this.contactDetailsForm.controls.last_name.patchValue(user?.last_name ?? fbString);
    this.contactDetailsForm.controls.business_account.patchValue(user?.business_account ?? fbBoolean);
    this.contactDetailsForm.controls.company_name.patchValue(user?.company_name ?? fbOptionalString);
    this.contactDetailsForm.controls.uid.patchValue(user?.uid ?? fbOptionalString);
    this.contactDetailsForm.controls.address.patchValue(user?.address ?? fbString);
    this.contactDetailsForm.controls.zip_code.patchValue(user?.zip_code ?? fbString);
    this.contactDetailsForm.controls.city.patchValue(user?.city ?? fbString);
    this.contactDetailsForm.controls.country.patchValue(<ISO2CountryCode>user?.country ?? 'AT');

    this.contactDetailsForm.updateValueAndValidity();
  }

  private setupValueChangeHandlers() {
    this.contactDetailsForm.controls.business_account.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isBusiness) => {
      const businessControls = [
        this.contactDetailsForm.controls.company_name,
        // this.contactDetailsForm.controls.address,
        // this.contactDetailsForm.controls.zip_code,
        // this.contactDetailsForm.controls.city,
        // this.contactDetailsForm.controls.country,
      ];
      if (isBusiness) {
        for (let control of businessControls) {
          control.setValidators([Validators.required]);
          control.updateValueAndValidity();
          control.markAsUntouched();
        }
      } else {
        for (let control of businessControls) {
          control.clearValidators();
          control.updateValueAndValidity();
        }
      }
    });
  }
}
