@if (garage) {
  <!-- Mobile -->
  <div class="block md:hidden text-center mb-4">
    <div class="headline-small mb-1" [innerText]="garage.name"></div>
    <div class="body-large">
      <span class="text-decoration-dark" [innerText]="formatAddress(garage)"></span>
    </div>
  </div>

  <!-- Desktop -->
  <div class="hidden md:block">
    <div class="headline-x-small mb-2">
      <span [innerText]="garage.name"></span>
      <span class="font-light">&nbsp;&nbsp;</span>
      <span class="font-light" [innerText]="formatAddress(garage)"></span>
    </div>
    <hr class="mb-4" />
  </div>

  <div class="flex flex-col lg:flex-row gap-4">
    <div class="lg:w-[50%] lg:flex-shrink-0">
      <div class="body-large mb-0.5">
        <div class="text-decoration-dark">
          <i class="fa-kit fa-contract mr-1.5"></i>
          <span class="font-medium" [innerText]="'CarParks.Contracts' | translate"></span>
          <span class="font-medium">:&nbsp;</span>
          <span class="font-light" [innerText]="'CarParks.LongTermParking' | translate"></span>
        </div>
      </div>
      <div class="body-small mb-2">
        <span class="text-neutral-50" [innerText]="'CarParks.LongTermParkingDescription' | translate"></span>
      </div>
      @if (garage.products && garage.products.length > 0) {
        <div class="flex flex-col gap-2">
          @for (product of garage.products; track product) {
            <div
              class="card card-small"
              [ngClass]="{
                'hover:bg-decoration-transparent-4 cursor-pointer': false,
              }"
              [routerLink]="false ? '/contracts/create' : null"
              [queryParams]="{ garageId: garage!.id, productId: product.id }"
            >
              <div class="card-body flex items-center justify-between gap-4 group">
                <div class="flex-shrink overflow-hidden text-left">
                  <div class="body-medium overflow-hidden whitespace-nowrap overflow-ellipsis mb-0.5">
                    <span class="font-medium text-decoration-dark" [innerText]="product.name"></span>
                  </div>
                  <div class="body-medium overflow-hidden whitespace-nowrap overflow-ellipsis">
                    <span class="text-neutral-30" [innerText]="product.description"></span>
                  </div>
                </div>
                <div class="flex items-center gap-4">
                  <div class="text-right">
                    <div class="body-medium text-interaction mb-0.5">
                      <span class="font-medium" [innerText]="product.price.amount | localizedCurrency: product.price.currency"></span>
                    </div>
                    <div class="body-medium" [innerText]="'Common.Monthly' | translate"></div>
                  </div>
                  @if (false) {
                    <div>
                      <i class="fa-kit fa-contract-new text-2xl text-interaction-dark group-hover:text-interaction"></i>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
        </div>

        <div class="body-medium mt-2">
          <span
            class="text-neutral-50"
            [innerText]="'Contracts.ContractsCanOnlyBeConcludedViaCustomerManagementDepartment' | translate"
          ></span>
        </div>
      } @else {
        <div class="body-medium text-neutral-50 text-center py-4" [innerText]="'CarParks.NoContractsAvailable' | translate"></div>
      }
    </div>
    <div class="lg:w-[50%] lg:flex-shrink-0">
      <div class="body-large mb-0.5">
        <div class="text-decoration-dark mb-0.5">
          <i class="fa-kit fa-time mr-1.5"></i>
          <span class="font-medium" [innerText]="'CarParks.TimeBasedParking' | translate"></span>
          <span class="font-medium">:&nbsp;</span>
          <span class="font-light" [innerText]="'CarParks.ShortTermParking' | translate"></span>
        </div>
      </div>
      <div class="body-small mb-2">
        <span class="text-neutral-50" [innerText]="'CarParks.ShortTermParkingDescription' | translate"></span>
      </div>
      <div class="card card-small">
        <div class="card-body">
          <table class="price-table">
            <th></th>
            <tr>
              <td class="body-medium align-top">
                <span class="font-medium text-decoration-dark" [innerText]="'CarParks.Tarifs.Wipark.ZoneOne' | translate"></span>
              </td>
            </tr>
            <tr>
              <td class="body-medium align-top">
                <span class="text-neutral-30" [innerText]="'CarParks.Tarifs.Wipark.HourlyRate' | translate"></span>
              </td>
              <td class="body-medium text-right align-top">
                <span class="font-medium text-interaction whitespace-nowrap" [innerText]="'€ 3,90'"></span>
              </td>
            </tr>
            <tr>
              <td class="body-medium align-top">
                <span class="text-neutral-30" [innerText]="'CarParks.Tarifs.Wipark.EveningFlatRate' | translate"></span>
              </td>
              <td class="body-medium text-right align-top">
                <span class="font-medium text-interaction whitespace-nowrap" [innerText]="'€ 7,90'"></span>
              </td>
            </tr>
            <tr>
              <td class="body-medium align-top">
                <span class="font-medium text-decoration-dark" [innerText]="'CarParks.Tarifs.Wipark.ZoneTwo' | translate"></span>
              </td>
            </tr>
            <tr>
              <td class="body-medium align-top">
                <span class="text-neutral-30" [innerText]="'CarParks.Tarifs.Wipark.HourlyRate' | translate"></span>
              </td>
              <td class="body-medium text-right align-top">
                <span class="font-medium text-interaction whitespace-nowrap" [innerText]="'€ 2,90'"></span>
              </td>
            </tr>
            <tr>
              <td class="body-medium align-top">
                <span class="text-neutral-30" [innerText]="'CarParks.Tarifs.Wipark.EveningFlatRate' | translate"></span>
              </td>
              <td class="body-medium text-right align-top">
                <span class="font-medium text-interaction whitespace-nowrap" [innerText]="'€ 5,90'"></span>
              </td>
            </tr>
            <tr>
              <td class="body-medium align-top">
                <span class="font-medium text-decoration-dark" [innerText]="'CarParks.Tarifs.Wipark.ZoneThree' | translate"></span>
              </td>
            </tr>
            <tr>
              <td class="body-medium align-top">
                <span class="text-neutral-30" [innerText]="'CarParks.Tarifs.Wipark.HourlyRate' | translate"></span>
              </td>
              <td class="body-medium text-right align-top">
                <span class="font-medium text-interaction whitespace-nowrap" [innerText]="'€ 1,90'"></span>
              </td>
            </tr>
            <tr>
              <td class="body-medium align-top">
                <span class="text-neutral-30" [innerText]="'CarParks.Tarifs.Wipark.EveningFlatRate' | translate"></span>
              </td>
              <td class="body-medium text-right align-top">
                <span class="font-medium text-interaction whitespace-nowrap" [innerText]="'€ 3,90'"></span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
}
