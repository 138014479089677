import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TypedRoute } from '../../../utils/router.utils';
import { CoreDataResolve } from '../../../models/routeTyping';
import { ArivoDialogComponent } from '../../ui-components/arivo-dialog/arivo-dialog.component';
import { ArivoDialogService } from '../../arivo-dialog-container/arivo-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { CoreDataService } from '../../../../core/services/core-data.service';

@Component({
  selector: 'app-logged-in-layout',
  templateUrl: './logged-in-layout.component.html',
  styleUrl: './logged-in-layout.component.scss',
})
export class LoggedInLayoutComponent implements OnInit {
  constructor(
    @Inject(ActivatedRoute) private route: TypedRoute<CoreDataResolve>,
    private dialogService: ArivoDialogService,
    private router: Router,
    private translateService: TranslateService,
    private coreDataService: CoreDataService,
  ) {}

  @ViewChild('homeScreenDialog') homeScreenDialog!: ArivoDialogComponent;

  ngOnInit() {
    let redirectByPaymentVerification = !!this.route.snapshot.queryParams['redirectResult'];
    this.route.data.subscribe({
      next: (data: CoreDataResolve) => {
        this.coreDataService.setCoreData(data.core_data);
        if (!this.showPaymentMethodReminderDialog && !redirectByPaymentVerification) {
          this.dialogService.addDialog({
            title: this.translateService.instant('Dialog.PaymentMethodMissing.Title'),
            subtitle: this.translateService.instant('Dialog.PaymentMethodMissing.Subtitle'),
            content: this.translateService.instant('Dialog.PaymentMethodMissing.Content'),
            dismissible: true,
            buttonText: this.translateService.instant('Dialog.PaymentMethodMissing.ButtonText'),
            buttonRoute: { link: '/billing', queryParams: { showPaymentForm: 'true' } },
            type: 'error',
          });
        }
      },
    });
  }

  get showPaymentMethodReminderDialog() {
    const isOnboardingOrBillingRoute = this.router.url.includes('/onboarding') || this.router.url.includes('/billing');
    return this.coreDataService.coreData.getValue()?.payment_method_available || isOnboardingOrBillingRoute;
  }
}
