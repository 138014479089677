import { Component, Directive, Host, HostListener, Input } from '@angular/core';
import { ArivoStepperComponent } from '../arivo-stepper.component';

export enum StepState {
  number = 'number',
  active = 'active',
  done = 'done',
}

@Component({
  selector: 'arivo-step',
  templateUrl: './arivo-step.component.html',
  styleUrl: './arivo-step.component.scss',
})
export class ArivoStepComponent {
  @Input() state: StepState = StepState.number;

  @Input() label: string = '';
}

@Directive({
  selector: 'button[arivoStepperPrevious]',
})
export class ArivoStepperPreviousDirective {
  constructor(@Host() private _arivoStepper?: ArivoStepperComponent) {}

  @HostListener('click')
  onClick() {
    if (this._arivoStepper) {
      this._arivoStepper.previous();
    }
  }
}

@Directive({
  selector: 'button[arivoStepperNext]',
})
export class ArivoStepperNextDirective {
  constructor(@Host() private _arivoStepper?: ArivoStepperComponent) {}

  @HostListener('click')
  onClick() {
    if (this._arivoStepper) {
      this._arivoStepper.next();
    }
  }
}
