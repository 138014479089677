<form [formGroup]="contractForm" (ngSubmit)="continue()">
  <div class="card-responsive mb-4">
    <div class="card-header">
      <h5 class="headline-x-small flex items-center gap-2">
        <i class="max-md:text-2xl fa-kit fa-parking-space"></i>
        <span [innerText]="'Contracts.SelectCarParkAndContract' | translate"></span>
      </h5>
    </div>
    <div class="card-body text-left">
      <h5 class="body-large text-decoration-dark mb-2" [innerText]="'Contracts.SelectCarPark' | translate"></h5>
      <div class="input-primary-container mb-6">
        <select class="input-primary" formControlName="garage">
          @for (garage of garages; track $index) {
            <option [value]="garage.id">
              {{ garage.name }} - {{ garage.street }} {{ garage.number }}, {{ garage.postalCode }} {{ garage.city }}
            </option>
          }
        </select>
        <label [innerText]="'Contracts.CarPark' | translate"></label>
      </div>

      @if (garage) {
        <h5 class="body-large text-decoration-dark mb-2" [innerText]="'Contracts.SelectContract' | translate"></h5>
        <div class="flex flex-col gap-2">
          @for (product of garage.products; track product) {
            <label class="button-select button-select-small button-select-radio flex-1">
              <input type="radio" [value]="product.id" formControlName="product" />
              <span>
                <div class="flex justify-between gap-2 md:gap-4 w-[100%]">
                  <div class="text-left">
                    <h6 class="body-medium text-decoration-dark mb-0.5">
                      <span class="font-medium" [innerText]="product.name"></span>
                    </h6>
                    <h6 class="body-medium">
                      <span class="text-neutral-50" [innerText]="product.description"></span>
                    </h6>
                  </div>
                  <div class="flex-grow flex justify-end gap-2 lg:gap-8">
                    <div class="text-right">
                      <h6 class="body-medium text-interaction mb-0.5">
                        <span class="font-medium" [innerText]="product.price.amount | localizedCurrency: product.price.currency"></span>
                      </h6>
                      <h6 class="body-medium" [innerText]="'Common.Monthly' | translate"></h6>
                    </div>
                  </div>
                </div>
              </span>
            </label>
          }
        </div>
      }
    </div>
  </div>

  <arivo-step-footer>
    <button class="button-primary" type="submit" [innerText]="'Common.Continue' | translate"></button>
    <button class="button-primary-text" type="button" (click)="back()" [innerText]="'Common.Back' | translate"></button>
  </arivo-step-footer>
</form>
