import { CanMatchFn, Router } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { inject } from '@angular/core';
import { isStandalone } from '../utils/platform.utils';

// App setup is only possible if it has not been done yet and if the app is running in standalone mode on a mobile device
export const checkAppSetupStatus: CanMatchFn = (route, segments) => {
  const platform = inject(Platform);
  const setupFinished = localStorage.getItem('setupFinished');
  return isStandalone() && (platform.ANDROID || platform.IOS || platform.SAFARI) && !setupFinished;
};

// If the app is running in standalone mode on a mobile device, the app must be set up first
export const requireAppSetup: CanMatchFn = (route, segments) => {
  const router = inject(Router);
  const platform = inject(Platform);
  const setupFinished = localStorage.getItem('setupFinished');
  if (isStandalone() && (platform.ANDROID || platform.IOS || platform.SAFARI) && !setupFinished) {
    router.navigateByUrl('/setup');
    return false;
  } else {
    return true;
  }
};
