export type Language = 'en' | 'de';

export type AvailableLanguage = {
  language: Language;
  title: string;
  ngLocale: any; // from @angular/common/locales/...
};

export const breakPoints = {
  '2xl': 1536,
  xl: 1280,
  lg: 1024,
  md: 768,
  sm: 640,
};
