<form [formGroup]="contactDetailsForm" (ngSubmit)="next()">
  <div class="card-responsive mb-4">
    <div class="card-header">
      <h5 class="headline-x-small">
        <i class="max-md:text-2xl fa-kit fa-data"></i>
        <span [innerText]="'SignUp.AddContactData' | translate"></span>
      </h5>
    </div>
    <div class="card-body flex justify-center mb-10 md:mb-0 items-center" *ngIf="!!paymentMethod">
      <div class="cta-large mr-10" [innerText]="'Onboarding.StoredPaymentMethod' | translate"></div>
      <div class="">
        @switch (paymentMethod.type) {
          @case ('scheme') {
            <div class="flex items-center gap-3 w-full h-6 md:h-8">
              <div class="headline-large mb-1">
                <i [ngClass]="creditCardIconClass"></i>
              </div>
              <div class="whitespace-nowrap flex items-center">
                <div class="mr-1 text-neutral-70">****</div>
                <div>{{ paymentMethod.card_summary }}</div>
              </div>
            </div>
          }
          @case ('googlepay') {
            <div class="flex items-center gap-3 w-full h-6 md:h-8">
              <i class="headline-large fa-brands fa-google-pay"></i>
            </div>
          }
          @case ('applepay') {
            <div class="flex items-center gap-3 w-full h-6 md:h-8">
              <i class="headline-large fa-brands fa-apple-pay"></i>
            </div>
          }
          @case ('sepadirectdebit') {
            <div class="flex items-center gap-3 w-full h-6 md:h-8">
              <i class="headline-large fa-light fa-money-bill-transfer"></i>
              <p class="truncate" dir="rtl">SEPA</p>
            </div>
          }
        }
      </div>
    </div>
    <div class="card-body">
      <div class="ar-input-group mb-2">
        <div class="input-primary-container">
          <input id="first_name" class="input-primary" addEmptyPlaceholder formControlName="first_name" />
          <label for="first_name" [innerText]="'SignUp.FirstName' | translate"></label>
        </div>
        <p class="error text-left" *formError="'first_name.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
      </div>
      <div class="ar-input-group mb-2">
        <div class="input-primary-container">
          <input id="last_name" class="input-primary" addEmptyPlaceholder formControlName="last_name" />
          <label for="last_name" [innerText]="'SignUp.LastName' | translate"></label>
        </div>
        <p class="error text-left" *formError="'last_name.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
      </div>
      <div class="ar-input-group mb-2">
        <div class="input-primary-container">
          <input id="address" class="input-primary" addEmptyPlaceholder formControlName="address" />
          <label for="address" [innerText]="'SignUp.Address' | translate"></label>
        </div>
        <p class="error text-left" *formError="'address.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
      </div>
      <div class="ar-input-group mb-2">
        <div class="input-primary-container">
          <input id="zip_code" class="input-primary" addEmptyPlaceholder formControlName="zip_code" />
          <label for="zip_code" [innerText]="'SignUp.PostalCode' | translate"></label>
        </div>
        <p class="error text-left" *formError="'zip_code.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
      </div>
      <div class="ar-input-group mb-2">
        <div class="input-primary-container">
          <input id="city" class="input-primary" addEmptyPlaceholder formControlName="city" />
          <label for="city" [innerText]="'SignUp.City' | translate"></label>
        </div>
        <p class="error text-left" *formError="'city.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
      </div>
      <div class="ar-input-group mb-2">
        <div class="input-primary-container">
          <select id="country" class="input-primary" formControlName="country">
            @for (country of selectableCountries; track country.iso2Code) {
              <option
                [value]="country.iso2Code"
                [innerText]="'CountryNames.' + country.name | translate"
                *ngIf="country.iso2Code !== 'XX'"
              ></option>
            }
          </select>
          <label for="country" [innerText]="'SignUp.Country' | translate"></label>
        </div>
        <p class="error text-left" *formError="'country.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
      </div>

      <div class="flex items-center gap-2 mt-4">
        <input id="business_account" type="checkbox" class="switch" formControlName="business_account" />
        <label for="business_account" class="body-medium" [innerText]="'SignUp.BusinessAccount' | translate"></label>
      </div>
      @if (contactDetailsForm.value.business_account) {
        <div class="ar-input-group mb-2 mt-4">
          <div class="input-primary-container">
            <input id="company_name" class="input-primary" addEmptyPlaceholder formControlName="company_name" />
            <label for="company_name" [innerText]="'SignUp.CompanyName' | translate"></label>
          </div>
          <p class="error text-left" *formError="'company_name.required'" [innerText]="'Common.Errors.ThisFieldIsRequired' | translate"></p>
        </div>
        <div class="ar-input-group mb-2">
          <div class="input-primary-container">
            <input id="uid" class="input-primary" addEmptyPlaceholder formControlName="uid" />
            <label for="uid" [innerText]="'SignUp.UIDOptional' | translate"></label>
          </div>
        </div>
      }
    </div>
  </div>

  <arivo-step-footer>
    <button class="button-primary" type="submit" [innerText]="'Common.Continue' | translate"></button>
  </arivo-step-footer>
</form>
