import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AccessCardApiService } from './access-card-api.service';

@Component({
  selector: 'app-access-card',
  templateUrl: './access-card.component.html',
  styleUrl: './access-card.component.scss',
})
export class AccessCardComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  qrCode: string | null = null;
  qrCodeLoading: boolean = false;

  constructor(private accessCardApiService: AccessCardApiService) {}

  ngOnInit() {
    this.qrCodeLoading = true;
    this.accessCardApiService
      .getAccessCardApiResponse()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (qrCodeBlob) => {
          this.qrCode = URL.createObjectURL(qrCodeBlob);
          this.qrCodeLoading = false;
        },
        error: (error) => {
          this.qrCode = null;
          this.qrCodeLoading = false;
        },
      });
  }
}
