import { Component, DestroyRef, inject, Inject } from '@angular/core';
import { ParkingSession } from '../../../shared/models/parkingSession';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../../shared/utils/router.utils';
import { DashboardResolve } from '../../../shared/models/routeTyping';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-dashboard-open-parking-sessions',
  templateUrl: './dashboard-open-parking-sessions.component.html',
  styleUrl: './dashboard-open-parking-sessions.component.scss',
})
export class DashboardOpenParkingSessionsComponent {
  openParkingSessions: ParkingSession[] = [];
  currentIndex: number = 0;
  private _destroyRef = inject(DestroyRef);

  constructor(@Inject(ActivatedRoute) private route: TypedRoute<DashboardResolve>) {
    this.route.data.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((data) => {
      this.openParkingSessions = data.dashboardApiResponse.open_parking_sessions;
    });
  }

  previousParkingSession(): void {
    this.currentIndex = this.currentIndex > 0 ? this.currentIndex - 1 : this.currentIndex;
  }

  nextParkingSession(): void {
    this.currentIndex = this.currentIndex < this.openParkingSessions.length - 1 ? this.currentIndex + 1 : this.currentIndex;
  }
}
