import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[addEmptyPlaceholder]',
})
export class AddEmptyPlaceholderDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  //Yes this is horrible, but it is a quick fix for the issue
  ngAfterViewInit() {
    const input = this.el.nativeElement;
    if (!input.getAttribute('placeholder')) {
      input.setAttribute('placeholder', ' ');
    }
  }
}
