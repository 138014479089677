import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SignupParkingSession } from '../../../../shared/models/parkingSession';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../../shared/services/alert.service';

@Component({
  selector: 'app-onboarding-finish-transfer',
  templateUrl: './onboarding-finish-transfer.component.html',
  styleUrl: './onboarding-finish-transfer.component.scss',
})
export class OnboardingFinishTransferComponent {
  @Input({ required: true }) parkingSession!: SignupParkingSession | null;

  @Output() completeProfileEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private route: ActivatedRoute,
    private alertService: AlertService,
  ) {
  }

  completeProfile() {
    if (this.parkingSession && !this.parkingSession.is_cleared) {
      this.alertService.add({
        message: 'SignUp.ParkingSessionCouldNotBeTransferredError',
        type: 'error',
        dismissible: false,
      });
    }

    this.completeProfileEvent.emit();
  }
}
