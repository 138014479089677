import { AfterContentInit, Component, ContentChildren, QueryList } from '@angular/core';
import { ArivoTabComponent } from './arivo-tab/arivo-tab.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'arivo-tabs',
  templateUrl: './arivo-tabs.component.html',
  styleUrl: './arivo-tabs.component.scss',
})
export class ArivoTabsComponent implements AfterContentInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  @ContentChildren(ArivoTabComponent) tabs!: QueryList<ArivoTabComponent>;

  ngAfterContentInit() {
    let activeTabs = this.tabs.filter((tab) => tab.active);

    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: ArivoTabComponent) {
    this.tabs.toArray().forEach((tab) => (tab.active = false));
    tab.active = true;
    if (tab.urlParamKey) {
      this.setUrlParam(tab.urlParamKey);
    }
  }

  setUrlParam(urlParamKey: string): void {
    this.router.navigate([], {
      queryParams: { activeTab: urlParamKey },
      queryParamsHandling: 'merge', // preserve other query params
      replaceUrl: true, // ensure the URL is updated without triggering resolvers
    });
  }
}
