<arivo-alerts></arivo-alerts>

<div>
  <div class="mb-8">
    <app-billing-account-balance></app-billing-account-balance>
  </div>
  <div>
    <arivo-tabs>
      <arivo-tab
        [title]="'Billing.ShortTermParkingTitle' | translate"
        icon="fa-kit fa-time"
        [active]="!tab || tab === 'parking'"
        urlParamKey="parking"
      >
        <app-billing-park-sessions></app-billing-park-sessions>
      </arivo-tab>
      <arivo-tab [title]="'Billing.InvoicesTitle' | translate" icon="fa-kit fa-wallet" [active]="tab === 'invoices'" urlParamKey="invoices">
        <app-billing-invoices></app-billing-invoices>
      </arivo-tab>
    </arivo-tabs>
  </div>
</div>
