import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DashboardApiResponse } from '../../shared/models/routeTyping';
import { HttpClient } from '@angular/common/http';
import { parseDate } from '../../shared/utils/common.utils';
import { LicensePlate, PayPerUseLicensePlate } from '../../shared/models/licensePlate';
import { exampleGarageContracts } from '../../core/utils/example.data';

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService {
  constructor(private http: HttpClient) {}

  getDashboardApiResponse(): Observable<DashboardApiResponse> {
    return this.http
      .get<DashboardApiResponse>(`/api/backoffice/v2/customer_portal/dashboard_data/`)
      .pipe(map((x) => deserializeDashboardApiResponse(x)));
  }

  togglePayPerUseLicensePlate(licensePlate: PayPerUseLicensePlate): Observable<PayPerUseLicensePlate> {
    return this.http.put<PayPerUseLicensePlate>(`/api/backoffice/v2/customer_portal/pay_per_use/toggle/`, licensePlate);
  }

  addPayPerUseLicensePlate(licensePlate: LicensePlate): Observable<PayPerUseLicensePlate> {
    return this.http.post<PayPerUseLicensePlate>(`/api/backoffice/v2/customer_portal/pay_per_use/`, {
      license_plate: licensePlate.license_plate,
      license_plate_country: licensePlate.license_plate_country,
    });
  }
}

function deserializeDashboardApiResponse(response: DashboardApiResponse): DashboardApiResponse {
  response.garage_contracts = exampleGarageContracts;

  response.garage_contracts = response.garage_contracts.map((contract) => {
    let updatedContract = {
      ...contract,
      start: parseDate(contract.start),
    };

    if (contract.end) {
      updatedContract = {
        ...updatedContract,
        end: parseDate(contract.end),
      };
    }
    return updatedContract;
  });

  response.open_parking_sessions = response.open_parking_sessions.map((parkingSession) => ({
    ...parkingSession,
    start_time: parseDate(parkingSession.start_time),
  }));
  return response;
}
