import { Injectable } from '@angular/core';
import { Garage } from '../../../shared/models/garage';
import { ContractTemplate } from '../../../shared/models/contractTemplate';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContractCreateService {
  constructor() {}

  garageSubject: Subject<Garage> = new Subject<Garage>();
  productSubject: Subject<ContractTemplate> = new Subject<ContractTemplate>();
}
