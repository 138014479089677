import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'arivo-paginator',
  templateUrl: './arivo-paginator.component.html',
  styleUrl: './arivo-paginator.component.scss',
})
export class ArivoPaginatorComponent {
  @Input() items: any[] = [];

  @Input() pageSize: number = 10;
  @Input() currentPage: number = 1;

  @Output() beforePageChange = new EventEmitter<number>();

  get startIndex(): number {
    return (this.currentPage - 1) * this.pageSize;
  }

  get start(): number {
    return this.startIndex + 1;
  }

  get endIndex(): number {
    return Math.min(this.startIndex + this.pageSize, this.items.length);
  }

  get end(): number {
    return this.endIndex;
  }

  get totalPages(): number {
    return Math.ceil(this.items.length / this.pageSize);
  }

  get totalCount(): number {
    return this.items.length;
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.beforePageChange.emit(this.currentPage);
      this.currentPage--;
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.beforePageChange.emit(this.currentPage);
      this.currentPage++;
    }
  }
}
