import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDiff',
})
export class DateDiffPipe implements PipeTransform {
  transform(date1: Date | string, date2: Date | string, unit: 'days' | 'hours' | 'minutes' = 'minutes'): number {
    const d1 = date1 instanceof Date ? date1 : new Date(date1);
    const d2 = date2 instanceof Date ? date2 : new Date(date2);
    const diffInMs = Math.abs(d1.getTime() - d2.getTime());

    switch (unit) {
      case 'minutes':
        return Math.floor(diffInMs / 60000);
      case 'hours':
        return Math.floor(diffInMs / 3600000);
      case 'days':
        return Math.floor(diffInMs / 86400000);
      default:
        throw new Error(`Invalid unit: ${unit}`);
    }
  }
}
