<div class="card">
  <div class="card-header">
    <div class="headline-large">Datenschutzerklärung</div>
  </div>
  <div class="card-body">
    <div class="headline-small">Datenschutzerklärung</div>
    <div class="headline-x-small">Version 2 (gültig ab 19.10.2023)</div>
    <div class="body-medium mt-2">
      Diese Mitteilung beschreibt wie der Garagenbetreiber Ihre personenbezogenen Daten verarbeiten (gemäß Art 13 Abs. 1 DSGVO).
    </div>
  </div>
  <div class="card-body">
    <div class="body-medium">
      <ol>
        <li class="font-bold text-lg">1. Zwecke der Datenverarbeitung</li>
        <li>Wir werden Ihre personenbezogenen Daten zu folgenden Zwecken verarbeiten:</li>
        <li>a) Dauerparkerverwaltung</li>
        <li>b) Tarifberechnung für registrierte Kurzparkkunden</li>
        <li>c) Kontrolle der Nutzungsbestimmungen (z.B. Parken über zwei Stellplätze)</li>
        <li>d) Kontrolle der rechtskonformen Verwendung (z.B. Überschreitung der Gratisparkzeit)</li>
        <li>e) Schutz der Einrichtungen (z.B. Vandalismus)</li>
        <li>f) Verfolgung missbräuchlicher Verwendung (z.B. Ausfahrt ohne Bezahlung)</li>
        <li>Diese Daten erheben wir mittels:</li>

        <ul class="list-disc list-inside">
          <li>der von Ihnen zur Verfügung gestellten Daten zur Vertragserstellung</li>
          <li>Bildaufzeichnung, Auswertung der Kennzeichendaten</li>
          <li>
            Die Bereitstellung Ihrer personenbezogenen Daten ist freiwillig. Auf die Bildaufzeichnung und die Erfassung von Kennzeichendaten
            wird entsprechend der gesetzlichen Vorgaben hingewiesen.
          </li>
        </ul>
      </ol>
      <ol>
        <li class="font-bold text-lg">2. Rechtsgrundlagen der Verarbeitung</li>

        <ul class="list-disc list-inside">
          <li>
            Für die Abrechnung der Parkgebühren (sowohl beim Kurzparken als auch bei Dauerparkern) ist die Rechtsgrundlage
            „Vertragserfüllung“ (Art. 6 Abs. 1 lit. b DSGVO)
          </li>
          <li>Für die Kennzeichenerfassung bei Dauerparkern ist die Rechtsgrundlage Ihre Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)</li>
          <li>
            Für die Kennzeichenerfassung bei Kurzparkern ist die Rechtsgrundlage das berechtigte Interesse des Verantwortlichen (Art. 6 Abs.
            1 lit. f DSGVO), welche darin bestehen, die oben unter Punkt 1 (a bis f) genannten Zwecke zu erreichen. Gegen diese
            Datenverarbeitung steht Ihnen ein Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO zu, wenn bei Ihnen Gründe vorliegen die sich aus
            Ihrer besonderen Situation ergeben.
          </li>
          <li>
            Für die Bildverarbeitung insbesondere des Einganges und des Zutrittsbereiches, der Ein- und Ausfahrtstore, der Kassen und
            Automaten, der Stiegenhäuser sowie der Parkdecks gemäß § 12 Abs. 3 Z 2 DSG. Die Bildaufzeichnungen werden nur im Anlassfall
            ausgewertet, wenn entweder das überwachte Objekt selbst (Garage) oder darin abgestellte Fahrzeuge Gegenstand einer
            Rechtsverletzung wurden.
          </li>
        </ul>
      </ol>
      <ol>
        <li class="font-bold text-lg">2. Rechtsgrundlagen der Verarbeitung</li>
        <li>Zu den oben genannten Zwecken werden Ihre personenbezogenen Daten an folgende Empfänger - im Anlassfall - übermittelt:</li>
        <ul class="list-disc list-inside">
          <li>Auftragsverarbeiter (z.B. Callcenter, Beschwerdemanagement)</li>
          <li>Abrechnungsplattformen (Tankkarten, Mobilitätskarten etc.)</li>
          <li>Versicherungen (z.B. bei Beschädigungen)</li>
          <li>Inkassobüro, Rechtsanwalt, Gerichte</li>
          <li>Behörden im Rahmen ihres Aufgabenbereiches</li>
          <li>Auftraggeber des Garagenbetreibers als Betriebsführer</li>
        </ul>
      </ol>
      <ol>
        <li class="font-bold text-lg">4. Speicherdauer</li>
        <li>a) Bei nicht registrierten Kunden:</li>
        <li>
          Die Anonymisierung des Bildes des Kennzeichens erfolgt bei normaler Parktransaktion in der Regel 24 Stunden nach der letzten
          aufgezeichneten Aktion(1). Bei abgeschlossenen Parkvorgängen mit offenen Kosten erfolgt die Anonymisierung 2 Tage nach Mahnung.
          Diese Daten werden für die Abmahnung und bei Anfechtungen als Beweismittel benötigt. Nicht registrierte Kunden, welche innerhalb
          der Gratiszeit ausfahren oder bei der Einfahrt umdrehen werden nach 5 Minuten anonymisiert. Die übrigen Daten werden nach Ablauf
          der steuerrechtlichen Aufbewahrungsfristen von sieben Jahren gelöscht.
        </li>
        <li>b) Bei registrierten Kunden:</li>
        <li>
          Ihre personenbezogenen Daten werden von uns nur so lange aufbewahrt, wie dies zur Vertragserfüllung erforderlich ist, um die unter
          Punkt 1 genannten Zwecke zu erreichen:
        </li>

        <ul class="list-disc list-inside">
          <li>
            Parkvorgänge von registrierten Kunden, welche keine Kosten erzeugt haben: 12 Stunden nach der letzten aufgezeichneten Aktion(1).
          </li>
          <li>Bei der Abrechnung von Parkzeiten mit Zahlung auf Rechnung: 12 Stunden nach Rechnungslegung (Monatsrechnung).</li>
          <li>Abgeschlossene Parkvorgänge mit offenen Kosten: 2 Tage nach Mahnung.</li>
          <li>Die übrigen Daten werden nach Ablauf der steuerrechtlichen Aufbewahrungsfristen von sieben Jahren gelöscht.</li>
          <li>
            Für Dauerparker gilt zusätzlich: Bei regulär abgeschlossenen Parktransaktionen: die letzten 30 Bewegungsdaten (Informationen
            über die Ein- und Ausfahrt, einschl. Bild des KFZ Kennzeichens) zum Zwecke der Nachvollziehbarkeiten von Missbrauch und der
            Abrechnung von Parküberzeiten.
          </li>
        </ul>
        <li>c) Bildverarbeitung ("Videoüberwachung"):</li>
        <li>
          Aufgezeichnete Daten sind, sofern sie nicht aus konkretem Anlass für die Verwirklichung der zu Grunde liegenden Schutz- oder
          Beweissicherungszwecke oder für Zwecke nach § 12 Abs 3 Z 2 DSG benötigt werden, spätestens nach 14 Tagen zu löschen bzw.
          anonymisieren. Die Abweichung zur gesetzlichen Löschfrist des § 13 Abs 3 DSG wird zur Feststellung von Zahlungsausfällen von
          Kreditkartentransaktionen benötigt, da der Garagenbetreiber erst nach bis zu zwei Wochen erfährt, dass eine
          Kreditkartentransaktion von der Bank nicht durchgeführt worden ist. Ohne eine Speicherung für 14 Tage wäre eine Geltendmachung von
          Ansprüchen nicht mehr möglich. Die Verwendung der Aufzeichnungen für andere Zwecke ist nicht zulässig.
        </li>
      </ol>
      <ol>
        <li class="font-bold text-lg">5. Ihre Rechte im Zusammenhang mit personenbezogenen Daten</li>
        <li>
          Ihnen stehen die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit und Widerspruch zu. Dafür wenden
          Sie sich an uns.
        </li>
        <li>
          Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche
          sonst in einer Weise verletzt worden sind, können Sie sich bei der Datenschutzbehörde beschweren.
        </li>
        <li>Sofern die Verarbeitung auf Ihrer Einwilligung beruht, können Sie diese jederzeit widerrufen.</li>
        <li>
          Hinsichtlich der Bildverarbeitung gilt, dass betroffene Personen unbeschadet des Auskunftsrechtes gemäß Art. 15 DSGVO nicht
          berechtigt sind, vom Garagenbetreiber Lichtbilder zu erhalten, sofern dadurch die Rechte und Freiheiten anderer Personen
          beeinträchtigt werden. Stattdessen erhält die betroffene Person eine Beschreibung ihres Verhaltens. Der Garagenbetreiber ist aber
          berechtigt, Bildaufzeichnungen an die zuständige Behörde (etwa eine Sicherheitsbehörde im Rahmen eines durch Anzeige eingeleiteten
          Ermittlungsverfahrens) zu übermitteln, weil beim Garagenbetreiber der begründete Verdacht entstanden ist, die Daten könnten eine
          von Amts wegen zu verfolgende strafbare Handlung dokumentieren. Ein solcher Verdacht kann auch durch Hinweis eines Kunden
          entstehen.
        </li>
      </ol>
      <ol>
        <li class="font-bold text-lg">6. Unsere Kontaktdaten</li>
        <li>Verantwortlicher: Firma/Anschrift/E-Mail-Adresse</li>
        <li>Datenschutzbeauftragter (falls vorhanden): Firma/Anschrift/E-Mail-Adresse</li>
        <li>
          Wir behalten uns das Recht vor, diese Datenschutzerklärung aufgrund rechtlicher oder technischer Entwicklung jederzeit anzupassen.
        </li>
      </ol>
      <ol>
        <li>(1) Nach der Einfahrt, wenn keine Ausfahrt vermerkt ist; nach der Ausfahrt; nach der Zahlung der offener Parkgebühren</li>
      </ol>
    </div>
  </div>
</div>
