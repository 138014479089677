<div class="card-responsive">
  <div class="card-header">
    <h5 class="headline-x-small">
      <i class="max-md:text-2xl fa-kit fa-mobile-phone"></i>
      <span [innerText]="'SignUp.AppSettings' | translate"></span>
    </h5>
  </div>
  <div class="card-body">
    @if (!isIOS) {
      <p class="mb-2 body-medium" [innerText]="'SignUp.InstallAppAndActivateNotificationsDescription' | translate"></p>
      <p class="mb-6 body-medium" [innerText]="'SignUp.FeaturesMightNotWorkInPrivateMode' | translate"></p>
    } @else {
      <p class="mb-6 body-medium" [innerText]="'SignUp.InstallAppNotificationsIOS' | translate"></p>
    }
    <div class="flex flex-col gap-2">
      <div
        class="card flex items-center justify-between gap-4 cta-medium px-4 py-2 cursor-pointer"
        (click)="pwaService.installApp(continueInTheAppDialog, instructionsDialog)"
      >
        <div class="flex items-center gap-4">
          <i class="text-2xl w-6 fa-kit fa-mobile-phone"></i>
          <p [innerText]="'SignUp.InstallApp' | translate"></p>
        </div>
        @if (pwaService.isAppInstalled) {
          <i class="text-xl fa-light fa-circle-check text-success-dark"></i>
        } @else {
          <i class="text-xl fa-light fa-chevron-right"></i>
        }
      </div>

      @if (!isIOS) {
        <div
          class="card flex items-center justify-between gap-4 cta-medium px-4 py-2"
          [class.bg-surface-50]="notificationsService.notificationStatus === 'unsupported'"
          [class.cursor-pointer]="notificationsService.notificationStatus === 'default'"
          (click)="notificationsService.activateNotifications()"
        >
          <div class="flex items-center gap-4">
            <i class="text-2xl w-6 fa-light fa-bell-ring"></i>
            <div class="text-left">
              <p [innerText]="'SignUp.ActivateNotifications' | translate"></p>
              @if (notificationsService.notificationStatus === 'unsupported') {
                <div class="body-small">
                  <span class="text-neutral-50" [innerText]="'SignUp.NotificationsAreNotSupported' | translate"></span>
                </div>
              }
            </div>
          </div>
          @if (notificationsService.notificationError) {
            <i
              class="text-xl fa-light fa-exclamation-triangle text-error"
              matTooltip="An error has occurred while trying to enable notifications."
            ></i>
          } @else if (notificationsService.notificationStatus === 'granted') {
            <i class="text-xl fa-light fa-circle-check text-success-dark"></i>
          } @else if (notificationsService.notificationStatus === 'denied') {
            <i class="text-xl fa-light fa-circle-xmark text-error"></i>
          } @else {
            <i class="text-xl fa-light fa-chevron-right"></i>
          }
        </div>
      }
    </div>
  </div>
</div>

<arivo-dialog #instructionsDialog>
  <div class="card">
    <div class="card-body">
      <div class="headline-x-small mb-2" [innerText]="'SignUp.InstallApp' | translate"></div>
      @if (isIOS) {
        <ol class="list-decimal list-inside body-medium mb-4">
          <li [innerHTML]="'SignUp.InstallAppIOSFirstStep' | translate"></li>
          <li [innerHTML]="'SignUp.InstallAppIOSSecondStep' | translate"></li>
          <li [innerHTML]="'SignUp.InstallAppIOSThirdStep' | translate"></li>
        </ol>
        <div class="body-medium text-center" [innerText]="'SignUp.InstallAppFinish' | translate"></div>
      } @else if (isFirefoxMobile) {
        <ol class="list-decimal list-inside body-medium mb-4">
          <li [innerHTML]="'SignUp.InstallAppFirefoxMobileFirstStep' | translate"></li>
          <li [innerHTML]="'SignUp.InstallAppFirefoxMobileSecondStep' | translate"></li>
          <li [innerHTML]="'SignUp.InstallAppFirefoxMobileThirdStep' | translate"></li>
        </ol>
        <div class="body-medium text-center" [innerText]="'SignUp.InstallAppFinish' | translate"></div>
      } @else {
        <div class="body-medium mb-1" [innerText]="'SignUp.InstallAppOtherBrowsers' | translate"></div>
        <div class="body-medium" [innerText]="'SignUp.InstallAppOtherBrowsersFinish' | translate"></div>
      }
    </div>
  </div>
</arivo-dialog>

<arivo-dialog #continueInTheAppDialog>
  <div class="card">
    <div class="card-body text-center">
      <div class="headline-x-small mb-2" [innerText]="'SignUp.ContinueInTheApp' | translate"></div>
      <div class="body-medium" [innerText]="'SignUp.ContinueInTheAppDescription' | translate"></div>
    </div>
  </div>
</arivo-dialog>
