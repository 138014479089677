import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../shared/models/user';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userData = new BehaviorSubject<User | null>(null);

  constructor(private http: HttpClient) {}

  setUser(user: User): void {
    this.userData.next(user);
  }

  getUserResponse(): Observable<User> {
    return this.http.get<User>(`/api/user`);
  }

  updateUser(user: User): Observable<User> {
    return this.http.put<User>(`/api/user`, user);
  }
}
