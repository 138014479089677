import { Component, Host, HostListener, Input } from '@angular/core';
import { ArivoAccordionComponent } from '../arivo-accordion.component';

@Component({
  selector: 'arivo-accordion-header',
  templateUrl: './arivo-accordion-header.component.html',
  styleUrl: './arivo-accordion-header.component.scss',
})
export class ArivoAccordionHeaderComponent {
  @Input() caretLeft = false;

  constructor(@Host() private _accordion: ArivoAccordionComponent) {}

  public get open(): boolean {
    return this._accordion.open;
  }

  @HostListener('click')
  toggleOpen(): void {
    this._accordion.toggleOpen();
  }
}
