import { Component, DestroyRef, EventEmitter, Inject, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ArivoStepperComponent } from '../../../../shared/components/ui-components/arivo-stepper/arivo-stepper.component';
import { UserService } from '../../../user/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ArivoPaymentService } from '../../../../shared/components/arivo-payment-container/arivo-payment.service';
import { ArivoPaymentContainerComponent } from '../../../../shared/components/arivo-payment-container/arivo-payment-container.component';
import { TranslateService } from '@ngx-translate/core';
import { ArivoDialogService } from '../../../../shared/components/arivo-dialog-container/arivo-dialog.service';
import { ArivoPaymentMethod } from '../../../../shared/models/common';
import { CoreDataService } from '../../../../core/services/core-data.service';
import { ActivatedRoute } from '@angular/router';
import { TypedRoute } from '../../../../shared/utils/router.utils';
import { OnboardingFinishResolve } from '../../../../shared/models/routeTyping';

@Component({
  selector: 'app-onboarding-finish-first-step',
  templateUrl: './onboarding-finish-first-step.component.html',
  styleUrl: './onboarding-finish-first-step.component.scss',
})
export class OnboardingFinishFirstStepComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  paymentMethod: ArivoPaymentMethod | null = null;
  creditCardIconClass: string | null = null;
  showErrorComponent: boolean = false;

  @Input({ required: true }) stepperComponent?: ArivoStepperComponent;
  @Output() cancelEvent: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('paymentContainer') private paymentContainer?: ArivoPaymentContainerComponent;

  constructor(
    private userService: UserService,
    private paymentService: ArivoPaymentService,
    private coreDataService: CoreDataService,
    private dialogService: ArivoDialogService,
    private translateService: TranslateService,
    @Inject(ActivatedRoute) private route: TypedRoute<OnboardingFinishResolve>,
  ) {}

  ngOnInit() {
    this.dialogService.addDialog({
      title: this.translateService.instant('Dialog.WelcomeWipark.Title'),
      content: this.translateService.instant('Dialog.WelcomeWipark.Content'),
      dismissible: true,
      dismissButtonText: this.translateService.instant('Dialog.WelcomeWipark.Dismiss'),
      type: 'info',
    });
    this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
      this.paymentMethod = data.onboardingFinishApiResolve.payment_method ?? null;
      if (this.paymentMethod) {
        this.creditCardIconClass = this.paymentService.getCreditCardIcon(this.paymentMethod.brand);
      }
    });
  }

  next(): void {
    if (this.stepperComponent && !!this.paymentMethod) {
      this.stepperComponent.selectedIndex = 1;
    }
  }
}
