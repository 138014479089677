import { Component, Input } from '@angular/core';
import { Garage } from '../../../shared/models/garage';

@Component({
  selector: 'app-garage-details',
  templateUrl: './garage-details.component.html',
  styleUrl: './garage-details.component.scss',
})
export class GarageDetailsComponent {
  @Input() garage?: Garage;

  constructor() {}

  formatAddress(garage: Garage): string {
    return `${garage.street} ${garage.number}, ${garage.postalCode} ${garage.city}`;
  }
}
