import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { NavigationCancel, ResolveEnd, ResolveStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private currentlyLoadingProcesses = 0;
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);
  public static readonly _instance: LoadingService;

  constructor(private router: Router) {
    (LoadingService as any)._instance = this;
    this.router.events
      .pipe(
        filter(
          (event): event is ResolveStart | ResolveEnd | NavigationCancel =>
            event instanceof ResolveStart || event instanceof ResolveEnd || event instanceof NavigationCancel,
        ),
      )
      .subscribe((e) => {
        if (e instanceof ResolveStart) {
          this.setLoading(true);
        }
        if (e instanceof ResolveEnd || e instanceof NavigationCancel) {
          this.setLoading(false);
        }
      });
  }

  public loadingChanges(): Observable<boolean> {
    return this.loadingBehaviorSubject.asObservable();
  }

  public setLoading(loading: boolean): void {
    if (loading) {
      this.currentlyLoadingProcesses++;
    } else {
      this.currentlyLoadingProcesses = Math.max(0, this.currentlyLoadingProcesses - 1);
    }
    this.loadingBehaviorSubject.next(this.currentlyLoadingProcesses > 0);
  }
}
